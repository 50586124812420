<script>
  import { onMount } from "svelte";
  import {
    calculatePositionActiveTab,
    getParamsAndSetActiveTabPosition,
    deleteIndicator,
    addEvent,
    polyfill
  } from "utils/renderUtils/tabsRenderUtils";
  import { getValueLocationParam } from "utils/urlUtils";

  export let onChange;
  export let setStoreTab;

  let activeTabIndex = +getValueLocationParam(location).tab || 0;
  let categoryIndex = +getValueLocationParam(location).categoryIndex || 0;
  let dataIndex = +getValueLocationParam(location).dataIndex || 0;
  setStoreTab(+activeTabIndex);
  let reel, indicator, tabs;

  onMount(() => {
    reel = document.getElementById("reel");
    indicator = document.getElementById("indicator");
    tabs = document.getElementById("tabs");
    let startx, starty, diffx, diffy, drag;
    polyfill();
    calculatePositionActiveTab(reel, indicator, tabs, +activeTabIndex);

    addEvent("mousedown", tabs, onMouseDown);
    addEvent("mousemove", tabs, onMouseMove);
    addEvent("mouseup", document.body, onMouseUp);

    function onMouseDown(e) {
      if (!e) {
        e = window.event;
      }
      if (e.target && e.target.nodeName === "NAV") {
        e.preventDefault();
      } else if (e.srcElement && e.srcElement.nodeName === "NAV") {
        e.returnValue = false;
      }
      startx = e.clientX + tabs.scrollLeft;
      starty = e.clientY + tabs.scrollTop;
      diffx = 0;
      diffy = 0;
      drag = true;
    }

    function onMouseMove(e) {
      if (drag === true) {
        if (!e) {
          e = window.event;
        }
        diffx = startx - (e.clientX + tabs.scrollLeft);
        diffy = starty - (e.clientY + tabs.scrollTop);
        tabs.scrollLeft += diffx;
        tabs.scrollTop += diffy;
      }
    }
    function onMouseUp(e) {
      if (!e) {
        e = window.event;
      }
      drag = false;
      let start = 1,
        animate = function() {
          let step = Math.sin(start);
          if (step <= 0) {
            window.cancelAnimationFrame(animate);
          } else {
            tabs.scrollLeft += diffx * step;
            tabs.scrollTop += diffy * step;
            start -= 0.02;
            window.requestAnimationFrame(animate);
          }
        };
      animate();
    }

    if (document.fonts)
      document.fonts.onloadingdone = () =>
        getParamsAndSetActiveTabPosition(
          reel,
          indicator,
          tabs,
          +activeTabIndex
        );
  });

  const slideToTab = (
    newTabIndex = 0,
    categoryIndex = 0,
    dataIndex = 0,
    typeEvent
  ) => e => {
    if (+newTabIndex !== +activeTabIndex) {
      if (onChange) {
        onChange({
          activeTabIndex,
          newTabIndex,
          categoryIndex,
          dataIndex,
          typeEvent
        });
      }
      let oldActiveIndex = +activeTabIndex;
      setTimeout(() => {
        deleteIndicator(reel, oldActiveIndex);
      }, 100);
      activeTabIndex = +newTabIndex;
      calculatePositionActiveTab(reel, indicator, tabs, +activeTabIndex);
    } else {
      if (onChange) {
        onChange({
          activeTabIndex,
          newTabIndex,
          categoryIndex,
          dataIndex,
          typeEvent
        });
      }
    }
  };
  window.addEventListener(
    "popstate",
    function(e) {
      const locationTabIndex = +getValueLocationParam(location).tab || 0;
      const locationCategoryIndex =
        +getValueLocationParam(location).categoryIndex || 0;
      const locationDataIndex = +getValueLocationParam(location).dataIndex || 0;

      slideToTab(
        locationTabIndex,
        locationCategoryIndex,
        locationDataIndex,
        "popstate"
      )(e);
    },
    false
  );

  window.slideToTab = slideToTab;
</script>

<style>
  .tabs {
    /*overflow-x: scroll;*/
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    white-space: nowrap;
  }
  .tabs::-webkit-scrollbar {
    display: none;
  }
  .tab_wrapper {
    display: flex;
    flex-direction: column;
    background: white;
    overflow: hidden;
    width: 100%;
  }
  .tab_wrapper header {
    width: 100%;
    border-radius: 10px 10px 0 0;
    display: flex;
    flex-direction: column;
  }
  .tab_wrapper header nav {
    width: 100%;
    display: flex;
    position: relative;
  }
  .tab_wrapper header nav .indicator {
    height: 3px;
    position: absolute;
    bottom: 0;
    left: 0;
    background: #0056d6;
    transition: 0.2s ease;
  }
  @media (max-width: 992px) {
    .tab_wrapper header nav {
      justify-content: center;
    }
  }
  @media (max-width: 767px) {
    .tab_wrapper header nav {
      justify-content: unset;
    }
  }
</style>

<div class="tab_wrapper max-width">
  <header>
    <nav id="tabs" class="tabs">
      <slot {activeTabIndex} {slideToTab} />
      <div id="indicator" class="indicator" />
    </nav>
  </header>
</div>
