<script>
  import { onMount } from "svelte";
  import { navigate } from "svelte-routing";
  import {
    activeDocInDocumentation,
    activeTabInDocumentation
  } from "../../store";
  import Menu from "components/Common/Menu/Menu.svelte";
  import { setDocUrl, getValueLocationParam } from "utils/urlUtils";

  export let tabInfo;
  export let menuContent;
  export let isLeftMenuOpen;
  export let isRightMenuOpen;
  export let setFalse;
  export let toggleLeftMenu;
  export let toggleRightMenu;
  let nextTitle = "";
  let prevTitle = "";

  function getActiveDocInTab() {
    const sesStorActiveDoc = JSON.parse(
      sessionStorage.getItem("documentPosition")
    );
    let newActiveDocInTab = {
      dataIndex: +getValueLocationParam(location).dataIndex || 0,
      tab: +getValueLocationParam(location).tab || 0
    };
    const activeDocInfo = Object.keys($activeDocInDocumentation);
    if (
      !activeDocInfo.length &&
      !!sesStorActiveDoc &&
      !!Object.keys(sesStorActiveDoc).length
    ) {
      activeDocInDocumentation.setActiveDocInDocumentation(sesStorActiveDoc);
    }
    if (
      !activeDocInfo.length ||
      !$activeDocInDocumentation[$activeTabInDocumentation] ||
      $activeDocInDocumentation[$activeTabInDocumentation].dataIndex !==
        newActiveDocInTab.dataIndex
    ) {
      activeDocInDocumentation.setActiveDocInDocumentation({
        ...$activeDocInDocumentation,
        [+newActiveDocInTab.tab]: {
          dataIndex: newActiveDocInTab.dataIndex
        }
      });
    }
    return $activeDocInDocumentation[+$activeTabInDocumentation];
  }

  let activeDocInTab = getActiveDocInTab();

  onMount(() => {
    activeDocInTab = getActiveDocInTab();
  });

  const selectCase = activeDocInTab => {
    let selectedCase;
    if (menuContent && activeDocInTab) {
      selectedCase = menuContent[activeDocInTab.dataIndex];
      nextTitle =
        menuContent[activeDocInTab.dataIndex + 1] &&
        menuContent[activeDocInTab.dataIndex + 1].title;
      prevTitle =
        menuContent[activeDocInTab.dataIndex - 1] &&
        menuContent[activeDocInTab.dataIndex - 1].title;
      if (!selectedCase) {
        selectedCase = menuContent[0];
      }
    }
    return selectedCase;
  };

  function getActiveDocInTabOrSetDefault() {
    if (!$activeDocInDocumentation[+$activeTabInDocumentation]) {
      activeDocInDocumentation.setActiveDocInDocumentation({
        ...$activeDocInDocumentation,
        [+$activeTabInDocumentation]: {
          dataIndex: 0
        }
      });
    }
    return $activeDocInDocumentation[+$activeTabInDocumentation];
  }
  $: activeDocInTab = getActiveDocInTabOrSetDefault(
    $activeDocInDocumentation,
    $activeTabInDocumentation
  );
  let selectedCase = selectCase(activeDocInTab);
  $: selectedCase = selectCase(activeDocInTab);

  /**
   * Выбор документации по клику
   * @param parentId - номер категории
   * @param id - номер документации
   */
  const changeCase = ({ id }) => {
    navigate(
      setDocUrl({
        tab: $activeTabInDocumentation,
        dataIndex: id
      })
    );
    activeDocInDocumentation.setActiveDocInDocumentation({
      ...$activeDocInDocumentation,
      [+$activeTabInDocumentation]: {
        dataIndex: id
      }
    });
    activeDocInTab = $activeDocInDocumentation[$activeTabInDocumentation];
    sessionStorage.setItem(
      "documentPosition",
      JSON.stringify($activeDocInDocumentation)
    );
    nextTitle =
      menuContent[activeDocInTab.dataIndex + 1] &&
      menuContent[activeDocInTab.dataIndex + 1].title;
    prevTitle =
      menuContent[activeDocInTab.dataIndex - 1] &&
      menuContent[activeDocInTab.dataIndex - 1].title;
    selectedCase = menuContent[id];
  };
</script>

<Menu
  {tabInfo}
  {menuContent}
  {selectedCase}
  {activeDocInTab}
  {changeCase}
  {nextTitle}
  {isLeftMenuOpen}
  {isRightMenuOpen}
  {setFalse}
  {toggleLeftMenu}
  {toggleRightMenu}
  {prevTitle} />
