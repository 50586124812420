<script>
  import { Link } from "svelte-routing";
  import LogoColored from "static/LogoColored.svelte";
  export let getProps;
</script>

<style>
  .logo {
    margin: 24px 43px 24px 0;
  }
  .header-left {
    display: flex;
    align-items: center;
  }
  nav {
    margin-top: 10px;
  }
  nav > :global(a) {
    text-decoration: none;
    color: #2e3e56;
    padding: 16px 32px 0;
    font-size: 18px;
    cursor: pointer;
    opacity: 0.7;
  }
  nav > :global(a:hover) {
    opacity: 1;
  }
  nav > :global(a.active) {
    padding: 8px 16px;
    border-bottom: 2px solid #0056d6;
    color: #0056d6 !important;
    opacity: 1 !important;
  }
  @media (max-width: 767px) {
    :global(nav > a) {
      display: none;
    }
    .logo {
      margin: 12px 24px 12px 0;
    }
    nav > :global(a.active) {
      padding: 8px 16px;
    }
    nav > :global(a) {
      padding: 8px 16px;
      font-size: 13px;
    }
    :global(a > .logo > svg) {
      width: 188px;
      height: 32px;
    }
    nav {
      margin-top: 3px;
    }
  }
  @media (min-width: 768px) {
    .logo {
      margin: 12px 12px 12px 0;
    }
    nav > :global(a.active) {
      padding: 8px 4px;
    }
    nav > :global(a) {
      padding: 8px 4px;
      font-size: 13px;
      margin: 0 4px;
    }
    :global(a > .logo > svg) {
      width: 188px;
      height: 32px;
    }
    nav {
      margin-top: 3px;
    }
  }
  @media (min-width: 992px) {
    .logo {
      margin: 12px 24px 12px 0;
    }
    nav > :global(a.active) {
      padding: 8px 16px;
    }
    nav > :global(a) {
      padding: 8px 16px;
      font-size: 14px;
    }
    :global(a > .logo > svg) {
      width: 212px;
      height: 36px;
    }
    nav {
      margin-top: 7px;
    }
  }
  @media (min-width: 1200px) {
    .logo {
      margin: 24px 24px 24px 0;
    }
    nav > :global(a.active) {
      padding: 12px 16px;
    }
    nav > :global(a) {
      padding: 12px 16px;
      font-size: 16px;
    }
    :global(a > .logo > svg) {
      width: 236px;
      height: 40px;
    }
    nav {
      margin-top: 8px;
    }
  }
  @media (min-width: 1400px) {
    .logo {
      margin: 24px 24px 24px 0;
    }
    nav > :global(a) {
      padding: 12px 16px;
      font-size: 18px;
    }
    :global(a > .logo > svg) {
      width: 260px;
      height: 44px;
    }
    nav {
      margin-top: 8px;
    }
  }
</style>

<div class="header-left">
  <Link to="." {getProps}>
    <div class="logo">
      <LogoColored />
    </div>
  </Link>
  <nav>
    <Link to="documentation" {getProps}>Документация</Link>
    <Link to="news" {getProps}>Новости</Link>
    <Link to="contact" {getProps}>Контакты</Link>
    <Link to="about" {getProps}>О нас</Link>
  </nav>
</div>
