export const MENU_LINKS = [
  {
    link: ".",
    title: "Главная"
  },
  {
    link: "documentation",
    title: "Документация"
  },
  {
    link: "news",
    title: "Новости"
  },
  {
    link: "contact",
    title: "Контакты"
  },
  {
    link: "about",
    title: "О нас"
  }
];
