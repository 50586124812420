<script>
  import { navigate } from "svelte-routing";
  import { activeNews } from "../../store";

  export let news;

  let goToSelectNews = () => {
    activeNews.setActiveNews(news);
    navigate(`news/${news.id}`);
  };
  const { date, title } = news;
</script>

<style>
  @keyframes border-appearance {
    from {
      transform: translateY(-200px);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }
  .container-news-item {
    margin: 24px 0 24px;
    overflow: hidden;
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    z-index: 1;
    cursor: pointer;
  }
  .wrapper-news-item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    word-break: break-word;
    flex-wrap: wrap;
    color: #fff;
    max-width: 300px;
    padding: 0 25px;
  }
  .news-date {
    opacity: 0.7;
    font-weight: normal;
    font-size: 14px;
    margin-bottom: 8px;
  }
  .news-text {
    font-size: 16px;
    font-weight: normal;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-text-size-adjust: none;
  }
  .border {
    flex: 0 0 3px;
    background: #ffffff;
    opacity: 0.24;
    z-index: 1;
  }
  .container-news-item:hover .border {
    opacity: 1;
    transition: 0.5s;
  }
  @media (max-width: 767px) {
    .wrapper-news-item {
      display: flex;
      align-items: flex-start;
      word-break: break-word;
      flex-wrap: wrap;
    }
    .news-date {
      font-size: 11px;
    }
    .news-text {
      font-size: 13px;
    }
  }
  @media (min-width: 768px) {
    .news-date {
      font-size: 12px;
    }
    .news-text {
      font-size: 14px;
    }
  }
  @media (min-width: 992px) {
    .news-date {
      font-size: 13px;
    }
    .news-text {
      font-size: 15px;
    }
  }
  @media (min-width: 1200px) {
    .news-date {
      font-size: 14px;
    }
    .news-text {
      font-size: 16px;
    }
  }
</style>

<div class="container-news-item" on:click={goToSelectNews}>
  <div class="border" />
  <div class="wrapper-news-item">
    <div class="news-date">{date}</div>
    <div class="news-text">{title}</div>
  </div>
</div>
