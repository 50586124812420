<script>
  import LeftHeader from "./TopHeader/LeftHeader.svelte";
  import RightHeader from "./TopHeader/RightHeader.svelte";
  import ContentWrapper from "components/ContentWrapper.svelte";

  export let getProps;
</script>

<style>
  .container {
    height: 98px;
  }
  .row {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .header-width {
    max-width: 1400px;
    margin: auto;
  }
  @media (max-width: 767px) {
    .container {
      height: 50px;
    }
  }
  @media (min-width: 768px) {
    .container {
      height: 70px;
    }
  }
</style>

<div class="container w-100 header-width">
  <ContentWrapper>
    <div class="row">
      <LeftHeader {getProps} />
      <RightHeader {getProps} />
    </div>
  </ContentWrapper>
</div>
