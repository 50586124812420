<script>
  import { beforeUpdate } from "svelte";
  export let menuContent;
  export let tabInfo;
  export let toggleLeftMenu;
  export let activeDocInTab;
  export let toggleRightMenu;
  export let isLeftMenuOpen;
  export let isRightMenuOpen;
  let title;
  let category;
  let headersTitle;
  const setTitle = () => {
    if (Array.isArray(menuContent)) {
      if (menuContent.length - 1) {
        const activeContent = menuContent[activeDocInTab.dataIndex];
        category = "";
        title = activeContent && activeContent.title;
        visibleLeftMenu = true;
      }
    } else {
      if (menuContent.categories.length - 1) {
        const activeContent =
          menuContent.categories[activeDocInTab.categoryIndex];
        category = activeContent && activeContent.name;
        visibleLeftMenu = true;
      }
      if (
        menuContent.categories[activeDocInTab.categoryIndex] &&
        menuContent.categories[activeDocInTab.categoryIndex].data.length - 1
      ) {
        const activeCategory =
          menuContent.categories[activeDocInTab.categoryIndex];
        const activeContent =
          activeCategory && activeCategory.data[activeDocInTab.dataIndex];
        title = activeContent && activeContent.title;
        visibleLeftMenu = true;
      }
    }
  };
  beforeUpdate(() => {
    setTitle();
  });
  let visibleLeftMenu = false;
  setTitle();
</script>

<style>
  .wrapper {
    position: sticky;
    top: 127px;
    background: #f2f3f5;
    z-index: 999;
  }
  .container {
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: row nowrap;
    font-size: 12px;
    height: 48px;
    overflow: hidden;
    padding: 8px 20px;
  }
  .hamburger {
    cursor: pointer;
    margin-right: 10px;
    position: relative;
    text-align: left;
    padding: 10px;
    border-radius: 50%;
  }
  .hamburger:hover {
    background-color: rgba(0, 86, 214, 0.06);
  }
  .title {
    border: 0;
    flex-grow: 1;
    font-size: 16px;
    font-weight: 600;
    line-height: 32px;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
  }
  .info {
    cursor: pointer;
    height: 32px;
    line-height: 32px;
    margin-right: -10px;
    padding: 0 10px;
    border-radius: 50%;
    width: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .info:hover {
    background-color: rgba(0, 86, 214, 0.06);
  }
  .hamburger-menu {
    width: 18px;
    height: 18px;
  }
  .line1,
  .line2,
  .line3 {
    width: 100%;
    height: 3px;
    background-color: #393939;
    margin: 3px 0;
    transition: 0.4s;
    border-radius: 10px;
  }
  .info-icon {
  }
  .icon-toc,
  .icon-toc:after,
  .icon-toc:before {
    border: 1px solid;
    border-radius: 50%;
    box-sizing: border-box;
    height: 4px;
    width: 4px;
    background: #000;
  }
  .icon-toc {
    box-sizing: border-box;
    display: inline-block;
    line-height: normal;
    position: relative;
    top: -1px;
    vertical-align: middle;
  }
  .icon-toc:after,
  .icon-toc:before {
    content: "";
    position: absolute;
  }
  .icon-toc::before {
    left: -1px;
    top: -7px;
  }
  .icon-toc:after {
    left: -1px;
    top: 5px;
  }
  .active-right .icon-toc {
    border-radius: 0;
    height: 16px;
    transform: rotate(45deg);
    width: 3px;
  }
  .active-right .icon-toc::before {
    border-radius: 0;
    height: 3px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 16px;
  }
  .active-right .icon-toc::after {
    content: "";
  }
  .active-left .line1 {
    transform: rotate(-45deg) translate(-3px, 5px);
  }
  .active-left .line2 {
    display: none;
  }
  .active-left .line3 {
    transform: rotate(45deg) translate(1px, -1px);
  }
  @media (max-width: 768px) {
    .wrapper {
      position: sticky;
      top: 102px;
    }
  }
  @media (min-width: 992px) {
    .container {
      display: none;
    }
  }
</style>

<div class="wrapper">
  <div class="container">
    {#if visibleLeftMenu}
      <div
        class="hamburger {isLeftMenuOpen && 'active-left'}"
        on:click={toggleLeftMenu}>
        <div class="hamburger-menu">
          <div class="line1" />
          <div class="line2" />
          <div class="line3" />
        </div>
      </div>
    {/if}
    <div class="title">
      {#if category && category !== "''"}
        <div>> {category}</div>
      {/if}
      {#if title}
        <div>> {title}</div>
      {/if}
    </div>
    <div
      class="info {isRightMenuOpen && 'active-right'}"
      on:click={toggleRightMenu}>
      <div class="info-icon" id="tocToggler">
        <i class="icon-toc" />
      </div>
    </div>
  </div>
</div>
