<script>
  import { getFileName } from "utils/index";
  import { DOWNLOAD_FILE_COLOR } from "constants/color";
  import { createLink } from "utils/urlUtils";
  export let news;

  let file = getFileName(news.files);
  let fileName = file.replace(/\.[^/.]+$/, "");
  let ext = /\.(.+)$/gm.exec(file)[1];
  let color;
  if (ext === DOWNLOAD_FILE_COLOR.pdf.name) {
    color = DOWNLOAD_FILE_COLOR.pdf.color;
  } else if (
    ext === DOWNLOAD_FILE_COLOR.doc.name ||
    ext === DOWNLOAD_FILE_COLOR.docx.name
  ) {
    color = DOWNLOAD_FILE_COLOR.doc.color;
  } else {
    color = DOWNLOAD_FILE_COLOR.default.color;
  }
</script>

<style>
  .wrapper-file {
    display: flex;
  }
  .container-file {
    display: flex;
    align-items: center;
    border-collapse: separate;
    border-collapse: initial;
    margin: 0 20px 20px 0;
    background: none;
    border-radius: 6px;
    width: auto;
    cursor: pointer;
    border: 1px solid;
    background-clip: padding-box;
    position: relative;
    color: #65757b;
    -webkit-background-clip: padding-box;
    -webkit-transition: border-color ease-in-out 0.25s,
      background ease-in-out 0.25s;
    transition: border-color ease-in-out 0.25s, background ease-in-out 0.25s;
  }
  .container-file:hover {
    cursor: pointer;
  }

  .ext {
    text-transform: uppercase;
  }
  .file-icon_pseudo {
    display: block;
    position: relative;
    width: 20px;
    padding: 4px 0;
  }
  .first {
    margin-bottom: 2px;
    display: block;
    width: 100%;
    height: 1px;
    -webkit-transition: background ease-in-out 0.25s;
    transition: background ease-in-out 0.25s;
  }
  .second {
    display: block;
    width: 80%;
    height: 1px;
    -webkit-transition: background ease-in-out 0.25s;
    transition: background ease-in-out 0.25s;
  }
  .file-name {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 0 28px;
    -webkit-transition: color ease-in-out 0.25s;
    transition: color ease-in-out 0.25s;
  }
  .file-name .name {
    font-size: 14px;
    font-weight: 500;
  }
  .file-icon {
    padding: 5px 10px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 12px;
    border-right: 1px solid rgba(var(--color), 0.3);
    -webkit-transition: all ease-in-out 0.25s;
    transition: all ease-in-out 0.25s;
  }
  .container-file {
    border-color: rgba(var(--color), 0.3);
  }
  .container-file .file-icon {
    color: rgba(var(--color), 1);
  }
  .container-file .file-icon .file-icon_pseudo .second {
    background: rgba(var(--color), 0.3);
  }
  .container-file .file-icon .file-icon_pseudo .first {
    background: rgba(var(--color), 0.3);
  }
  .container-file .file-name {
    color: rgba(var(--color), 1);
  }
  .container-file:hover {
    background: rgba(var(--color), 0.8);
    border-color: rgba(var(--color), 0.8);
    background-clip: padding-box;
    -webkit-background-clip: padding-box;
  }
  .container-file:hover .file-name {
    color: #ffffff;
  }
  .container-file:hover .file-icon {
    color: rgba(255, 255, 255, 0.8);
  }
  .container-file:hover .file-icon .file-icon_pseudo .first {
    background: rgba(255, 255, 255, 0.8);
  }
  .container-file:hover .file-icon {
    border-color: rgba(255, 255, 255, 0.8);
  }
  .container-file:hover .file-icon .file-icon_pseudo .second {
    background: rgba(255, 255, 255, 0.8);
  }
</style>

<div class="wrapper-file">
  <div
    class="container-file"
    on:click={() => createLink(news.files)}
    style="--color: {color}">
    <div class="file-icon">
      <div class="ext">{ext}</div>
      <div class="file-icon_pseudo">
        <strong class="first" />
        <strong class="first" />
        <strong class="first" />
        <strong class="second" />
      </div>
    </div>
    <div class="file-name">
      <div class="name">{fileName}</div>
    </div>
  </div>
</div>
