<script>
  import MailIcon from "static/MailClose.svelte";
  import PhoneIcon from "static/Phone.svelte";
  import UserIcon from "static/User.svelte";
  export let children;
  export let mail;
  export let phone;
  export let fio;
  let isEmail = !!mail;
  let isFio = !!fio;
</script>

<style>
  .link-unset a {
    text-decoration: none;
    color: #fff;
  }
  .phone-text {
    color: #fff;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .email-text {
    color: #fff;
    font-size: 14px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 4px;
  }
  .info-icon {
    width: 20px;
    height: 20px;
    margin-right: 12px;
  }
  :global(.info-icon > svg > path) {
    fill: #fff;
  }
</style>

{#if isFio}
  <div class="email-text link-unset">
    <div class="info-icon">
      <UserIcon />
    </div>
    <slot children />
  </div>
{:else if isEmail}
  <div class="email-text link-unset">
    <div class="info-icon">
      <MailIcon />
    </div>
    <a href="mailto:{mail}">
      <slot children />
    </a>
  </div>
{:else}
  <div class="phone-text link-unset">
    <div class="info-icon">
      <PhoneIcon />
    </div>
    <a href="tel:{phone}">
      <slot children />
    </a>
  </div>
{/if}
