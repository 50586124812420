import { createForm } from "final-form";
import validation from "components/Integration/validation";
import onSubmit from "components/Integration/submitForm";

const form = createForm({
  onSubmit,
  initialValues: {
    FIO: "",
    organization: "",
    email: "",
    phone_mobile: "",
    services: []
  },
  validate: validation
});

export default form;
