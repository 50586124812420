export const DOWNLOAD_FILE_COLOR = {
  pdf: {
    name: "pdf",
    color: "229, 39, 19"
  },
  doc: {
    name: "doc",
    color:"47,129,183"
  },
  docx: {
    name: "docx",
    color: "47,129,183"
  },
  default: {
    color: "0, 86, 214"
  }
};
