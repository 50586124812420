export const METRIC_STAND_PARAMS = {
  test: 86779989,
  demo: 86807873,
  release: 86807892
};

export const YA_COUNTER_PARAMS = {
  clickmap: true,
  trackLinks: true,
  accurateTrackBounce: true,
  webvisor: true
};

// export const yaCounter = () =>
//   (function(m, e, t, r, i, k, a) {
//     m[i] =
//       m[i] ||
//       function() {
//         (m[i].a = m[i].a || []).push(arguments);
//       };
//     m[i].l = 1 * new Date();
//     (k = e.createElement(t)),
//       (a = e.getElementsByTagName(t)[0]),
//       (k.async = 1),
//       (k.src = r),
//       a.parentNode.insertBefore(k, a);
//   })(window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");

export const YM_TARGET_TYPES = {
  // Добавление организации (если будет нужны в АО5)
};
