<script>
  import ContactCard from "../ContactCard.svelte";
  import { CONTACT_TYPES } from "constants/contacts";
  export let contact;
</script>

<style>
  .footer-info-right {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    flex: 1;
  }
  .text-helper {
    color: #fff;
    opacity: 0.5;
    font-size: 14px;
    margin-bottom: 10px;
    display: flex;
    flex-wrap: wrap;
  }
  .contact-info-wrapper {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
  }
  .contact-info {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    margin-bottom: 20px;
  }
  .contact-info:not(:last-child) {
    margin-right: 44px;
  }
  .contact-info > div:not(:last-child) {
    margin-bottom: 8px;
  }
  @media (max-width: 767px) {
    .footer-info-right {
      width: 100%;
      align-items: center;
    }
    .contact-info-wrapper {
      width: 100%;
    }
  }
  @media (min-width: 768px) {
    .footer-info-left {
      align-items: flex-start;
    }
  }
</style>

<div class="footer-info-right">
  <div class="text-helper">{CONTACT_TYPES.techAssistance}:</div>
  <div class="contact-info-wrapper">
    {#each contact as { email }}
      <div class="contact-info">
        <ContactCard mail={email}>{email}</ContactCard>
      </div>
    {/each}
  </div>
</div>
