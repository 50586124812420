<script>
  export let openedMenuIndexes;
  export let data;
  export let activeDocInTab;
  export let changeCaseObject;
  export let i;
</script>

<style>
  .menu-content {
    width: 100%;
  }
  .open {
    display: block;
  }
  .close {
    display: none;
  }
  .menu-item-wrapper {
    position: relative;
    width: 100%;
  }
  .menu-item {
    width: 100%;
    display: flex;
    position: relative;
    box-sizing: border-box;
    text-align: left;
    align-items: center;
    justify-content: flex-start;
    text-decoration: none;
    border-left: 3px solid transparent;
    padding-left: 8px;
    color: #2e3e56;
    font-weight: normal;
    font-size: 16px;
    transition: all 300ms;
  }
  .menu-item div {
    transition: all 300ms;
    color: #2e3e56;
  }
  .menu-item:hover {
    background-color: rgba(0, 86, 214, 0.06);
  }
  .menu-item:hover div {
    color: #0056d6;
  }
  .menu-item-active {
    border-color: #0056d6;
  }
  .menu-item-active div {
    color: #0056d6;
  }
  .menu-text {
    width: 100%;
    font-size: 14px;
    padding: 8px;
    transform: scaleX(1);
    flex: 1 1 auto;
    min-width: 0;
    text-decoration: none;
    border: none;
    transition: color 0.3s;
    cursor: pointer;
  }
</style>

<div class="menu-content {openedMenuIndexes.includes(i) ? 'open' : 'close'}">
  {#each data as { title }, j}
    <div class="menu-item-wrapper">
      <li
        class="menu-item {j === activeDocInTab.dataIndex && i === activeDocInTab.categoryIndex && 'menu-item-active'}">
        <div
          class="menu-text scroll"
          on:click="{() => changeCaseObject({ parentId: i, id: j })}">
          {title}
        </div>
      </li>
    </div>
  {/each}
</div>
