<script>
  import ContentWrapper from "components/ContentWrapper.svelte";
  import NewsPageCard from "components/News/NewsPageCard.svelte";

  window.scrollTo(0, 0);
</script>

<div class="w-100 max-width">
  <ContentWrapper>
    <NewsPageCard />
  </ContentWrapper>
</div>
