<script>
  import { navigate } from "svelte-routing";
  import { activeService, activeDoc } from "../../../store";
  export let isShowAllService;
  export let service;

  let goToSelectService = () => {
    if ($activeService !== service) {
      activeDoc.setActiveDoc({});
      sessionStorage.setItem("docPosition", JSON.stringify($activeDoc));
    }
    activeService.setActiveService(service);
    navigate(`/service/${service.link}`);
  };
</script>

<style>
  .service-card-wrapper {
    cursor: pointer;
    flex: 0 0 calc(20% - 16px);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
  .service-card-wrapper:hover .service-icon {
    background-color: rgba(0, 86, 214, 0.06);
  }
  .service-card-wrapper:hover .service-text {
    color: #0056d6;
  }
  .service-icon {
    width: 131px;
    height: 131px;
    background: white;
    margin: 1em;
    border-radius: 50%;
    transition: 0.4s;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  :global(.service-icon svg) {
    fill: #0056d6;
    opacity: 0.8;
    width: 75px;
    height: 75px;
  }
  :global(.service-icon svg path) {
    fill: #0056d6;
  }
  :global(.service-icon svg) {
    fill: #0056d6;
    opacity: 0.8;
  }
  :global(.service-icon svg g) {
    opacity: 1;
  }
  .service-text {
    font-size: 14px;
    text-align: center;
    font-weight: bold;
    transition: 0.4s;
  }
  @media (max-width: 373px) {
    .service-card-wrapper {
      flex: 0 1 100%;
    }
  }

  @media (max-width: 557px) {
    .service-card-wrapper {
      flex: 1 0 calc(50% - 16px);
    }
  }
  @media (max-width: 765px) {
    .service-card-wrapper {
      flex: 0 0 calc(33% - 16px);
    }
  }
  @media (max-width: 992px) {
    .service-card-wrapper {
      flex: 0 0 calc(25% - 16px);
    }
  }
</style>

<div class="service-card-wrapper" on:click={goToSelectService}>
  <div class="service-icon">
    {@html service.icon}
  </div>
  <div class="service-text">{service.title}</div>
</div>
