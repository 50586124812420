<script>
  import NewItem from "components/Header/NewsItem.svelte";
  export let newsInfo;
</script>

<style>
  .news-container {
    display: flex;
    flex-direction: column;
  }
  @media (max-width: 767px) {
    .news-container {
      display: flex;
      flex-direction: row;
    }
  }
</style>

<div class="news-container">
  {#each newsInfo as news, i}
    {#if i < 2}
      <NewItem {news} />
    {/if}
  {/each}
</div>
