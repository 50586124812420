<script>
  import OneDocItem from "./LeftMenu/OneDocItem.svelte";
  import ExpansionDocItem from "./LeftMenu/ExpansionDocItem.svelte";
  export let menuContent;
  export let tabInfo;
  export let activeDocInTab;
  export let changeCaseObject;
  export let isLeftMenuOpen;

  let isArray = Array.isArray(menuContent);
  const notCategory = name => {
    return !name || name === "''" || name === '""';
  };
</script>

<style>
  .container {
    display: flex;
    flex: 0 0 215px;
    flex-direction: column;
    top: 30px;
    max-height: 60vh;
    /*height: calc(100vh - 50px);*/
    position: -webkit-sticky;
    position: sticky;
    overflow-y: auto;
    margin-bottom: 16px;
  }
  .menu-title {
    overflow: auto;
  }
  @media (max-width: 1200px) {
    .container {
      flex: 0 0 200px;
    }
  }
  @media (max-width: 992px) {
    .container {
      display: none;
      top: 200px !important;
    }
    .open {
      background: #fff;
      width: 100%;
      z-index: 999;
      height: 100%;
    }
    .open.container {
      max-height: unset;
      overflow-y: auto;
      top: 105px;
      display: flex;
      flex: unset;
    }
  }
</style>

<div class="container {isLeftMenuOpen && 'open'}">
  {#if isArray}
    {#if menuContent.length > 1}
      <div class="menu-title" id={`menu-scrollable-tree${tabInfo}`}>
        {#each menuContent as { title }, i}
          <OneDocItem
            {tabInfo}
            {changeCaseObject}
            {activeDocInTab}
            {title}
            {isArray}
            {i} />
        {/each}
      </div>
    {/if}
  {:else if menuContent.categories.length > 1 || (menuContent.categories[activeDocInTab.categoryIndex] && menuContent.categories[activeDocInTab.categoryIndex].data.length) > 1}
    <div class="menu-title" id={`menu-scrollable-tree${tabInfo}`}>
      {#each menuContent.categories as { name, data }, i}
        {#if notCategory(name)}
          {#each data as { title }, j}
            <OneDocItem
              {tabInfo}
              {changeCaseObject}
              {activeDocInTab}
              {title}
              {isArray}
              {i}
              {j} />
          {/each}
        {:else}
          <ExpansionDocItem
            {i}
            {activeDocInTab}
            {name}
            {data}
            {changeCaseObject} />
        {/if}
      {/each}
    </div>
  {/if}
</div>
