<script>
  import Login from "static/Login.svelte";
  import HumburgerWrapper from "./HumburgerWrapper.svelte";
  export let getProps;
</script>

<style>
  .header-right label {
    color: #2e3e56;
    display: none;
    padding: 5px 10px;
    cursor: pointer;
    transition: 0.3s;
  }
  .header-right {
    display: flex;
    align-items: center;
  }
  .wrapper-lk {
    display: flex;
    align-items: center;
    text-decoration: none;
  }
  .text-lk {
    font-size: 16px;
    color: #2e3e56;
    opacity: 0.5;
    margin-right: 6px;
  }
  .link-lk {
    text-decoration: none;
    color: #2e3e56;
    font-size: 16px;
    cursor: pointer;
  }
  .wrapper-lk:hover .link-lk {
    opacity: 0.8;
  }
  .wrapper-lk:hover .icon-lk-2 {
    opacity: 0.8;
  }
  .icon-lk-2 {
    width: 32px;
    height: 32px;
    margin-left: 8px;
  }
  :global(.icon-lk-2 svg) {
    fill: #2e3e56;
  }
  @media (max-width: 767px) {
    .header-right label {
      display: inline-block;
    }
    .wrapper-lk {
      display: none;
    }
    .header-right {
      margin: 0;
    }
    .text-lk {
      font-size: 14px;
    }
    .link-lk {
      font-size: 14px;
    }
  }
  @media (min-width: 768px) {
    .header-right {
      margin: 8px 0 4px;
    }
    .text-lk {
      font-size: 13px;
    }
    .link-lk {
      font-size: 13px;
    }
    :global(.icon-lk-2 > svg) {
      width: 24px;
      height: 24px;
    }
    .icon-lk-2 {
      width: 24px;
      height: 24px;
    }
  }
  @media (min-width: 992px) {
    .header-right {
      margin: 12px 0 6px;
    }
    .link-lk {
      font-size: 14px;
    }
    .text-lk {
      font-size: 14px;
    }
    :global(.icon-lk-2 > svg) {
      width: 24px;
      height: 24px;
    }
    .icon-lk-2 {
      width: 24px;
      height: 24px;
    }
    nav {
      margin-top: 6px;
    }
  }
  @media (min-width: 1200px) {
    .header-right {
      margin: 12px 0 6px;
    }
    .text-lk {
      font-size: 15px;
    }
    .link-lk {
      font-size: 15px;
    }
    :global(.icon-lk-2 > svg) {
      width: 32px;
      height: 32px;
    }
    .icon-lk-2 {
      width: 32px;
      height: 32px;
    }
  }
  @media (min-width: 1400px) {
    .header-right {
      margin: 12px 0 6px;
    }
    .text-lk {
      font-size: 16px;
    }
    .link-lk {
      font-size: 16px;
    }
    :global(.icon-lk > svg) {
      width: 32px;
      height: 32px;
    }
    .icon-lk {
      width: 32px;
      height: 32px;
    }
  }
</style>

<div class="header-right">
  <HumburgerWrapper {getProps} />
  <a class="wrapper-lk" href="https://platform.astral.ru/lk">
    <div class="text-lk">Войти в</div>
    <div class="link-lk">Личный кабинет</div>
    <div class="icon-lk-2">
      <Login />
    </div>
  </a>
</div>
