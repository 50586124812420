<script>
  import HeaderItem from "./ExpansionDocItem/HeaderItem.svelte";
  import DocItem from "./ExpansionDocItem/DocItem.svelte";

  export let i;
  export let activeDocInTab;
  export let name;
  export let changeCaseObject;
  export let data;

  //TODO Удалить если не нужно будет переделывать меню
  // let expendedLevelIndex = 0;
  // Выбор одного пункта меню
  // const showNewLevelMenu = level1 => {
  //   expendedLevelIndex = level1;
  // };

  let openedMenuIndexes = [];

  /**
   * Открытие панели
   * @param index - номер открываемой панели
   */
  const showMenu = index => {
    if (openedMenuIndexes.includes(index)) {
      openedMenuIndexes = openedMenuIndexes.filter(el => el !== index);
    } else {
      openedMenuIndexes = [...openedMenuIndexes, index];
    }
  };

  /**
   * Открыть активную документацию
   */
  showMenu(activeDocInTab.categoryIndex);
</script>

<style>
  .menu-list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
  }
  /*:global(.menu-list-active) {*/
  /*  background-color: rgba(0, 86, 214, 0.03);*/
  /*  border-radius: 12px;*/
  /*}*/
</style>

<div class="menu-list {openedMenuIndexes.includes(i) && 'menu-list-active'}">
  <HeaderItem {i} {activeDocInTab} {showMenu} {name} {openedMenuIndexes} />
  <DocItem {i} {openedMenuIndexes} {data} {activeDocInTab} {changeCaseObject} />
</div>
