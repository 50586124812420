<script>
  import { onMount } from "svelte";
  import { navigate } from "svelte-routing";
  import { activeTab, activeDoc } from "../../store";
  import ServicePopover from "components/Common/ServicePopover.svelte";
  import Tabs from "components/Common/Tabs/Tabs.svelte";
  import ContentWrapper from "components/ContentWrapper.svelte";
  import Tab from "components/Common/Tabs/Tab.svelte";
  import { setServiceUrl } from "utils/urlUtils";

  export let name;
  export let title;
  export let icon;
  export let setFalse;
  export let tabInfoArray;

  const checkMobile = () => {
    const width = document.documentElement.clientWidth;
    return width <= 988;
  };

  let isMobile = checkMobile();

  onMount(() => {
    window.addEventListener("resize", () => {
      isMobile = checkMobile();
    });
  });

  const onChange = ({ newTabIndex, categoryIndex, dataIndex, typeEvent }) => {
    setFalse();
    let url = "";
    let activeDocInTab = $activeDoc[newTabIndex];
    activeTab.setActiveTab(newTabIndex);
    if (Number.isInteger(dataIndex)) {
      if (
        activeDocInTab &&
        activeDocInTab.categoryIndex === categoryIndex &&
        activeDocInTab.dataIndex === dataIndex
      ) {
        activeDoc.setActiveDoc({ ...$activeDoc, ...activeDocInTab });
      } else {
        activeDoc.setActiveDoc({
          ...$activeDoc,
          [+newTabIndex]: { categoryIndex, dataIndex }
        });
      }
      activeDocInTab = $activeDoc[newTabIndex];
      url = setServiceUrl({
        name,
        tab: +newTabIndex,
        categoryIndex,
        dataIndex,
        ...activeDocInTab
      });
    } else {
      url = setServiceUrl({ name, tab: +newTabIndex, ...activeDocInTab });
    }
    typeEvent !== "popstate" && navigate(url);
  };
</script>

<style>
  .container-info-icon {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
  }
  .content-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #0056d6;
    border-radius: 50%;
    padding: 24px;
    margin: 0 36px 12px 0;
  }
  :global(.content-icon svg) {
    fill: #fff;
    width: 75px;
    height: 75px;
  }
  :global(.content-icon svg path) {
    fill: #fff;
  }
  :global(.content-icon svg g) {
    opacity: 1;
  }
  .content-info-text {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
  .content-title {
    color: #2e3e56;
    font-weight: 500;
    font-size: 40px;
    margin-left: 28px;
  }
  .content-buttons-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }
  .tab-text {
    padding: 15px 30px;
    user-select: none;
  }
  .sticky {
    position: sticky;
    top: 70px;
    background: #fff;
    z-index: 999;
  }
  @media (max-width: 992px) {
    .container-info-icon {
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
    .content-icon {
      margin: 0;
    }
    :global(.content-icon svg) {
      width: 75px;
      height: 75px;
    }
    .content-info-text {
      align-items: center;
    }
    .content-title {
      font-size: 24px;
      text-align: center;
      padding: 4px 12px;
    }
    .content-buttons-wrapper {
      justify-content: center;
      align-items: center;
    }
    .tab-text {
      padding: 15px 20px;
    }
  }
  @media (max-width: 768px) {
    .sticky {
      position: sticky;
      top: 50px;
    }
  }
</style>

<div class="max-width">
  <ContentWrapper>
    <div class="container-info-icon">
      <div class="content-icon">
        {@html icon}
      </div>
      <div class="content-info-text">
        <div class="content-title">{title}</div>
        {#if !isMobile}
          <div class="content-buttons-wrapper">
            <Tabs
              let:activeTabIndex
              let:slideToTab
              {onChange}
              setStoreTab={activeTab.setActiveTab}>
              {#each tabInfoArray as { title, index }, i}
                <Tab value={i} {activeTabIndex} {slideToTab}>
                  <div class="tab-text">{title}</div>
                </Tab>
              {/each}
            </Tabs>
            <ServicePopover />
          </div>
        {/if}
      </div>
    </div>
  </ContentWrapper>
</div>
{#if isMobile}
  <div class="content-buttons-wrapper sticky">
    <Tabs
      let:activeTabIndex
      let:slideToTab
      {onChange}
      setStoreTab={activeTab.setActiveTab}>
      {#each tabInfoArray as { title, index }, i}
        <Tab value={i} {activeTabIndex} {slideToTab}>
          <div class="tab-text">{title}</div>
        </Tab>
      {/each}
    </Tabs>
    <ServicePopover />
  </div>
{/if}
