import { METRIC_STAND_PARAMS, yaCounter } from "constants/metrics";

export const getMetricsStandParams = () => {
  const STAND = process.env.STAND || "test";
  return METRIC_STAND_PARAMS[STAND.toLowerCase()] || METRIC_STAND_PARAMS.test;
};

export const getYaCounter = () => {
  if (!window.ym) {
    const counterId = getMetricsStandParams();
    // return yaCounter(counterId);
  }
};
