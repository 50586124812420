<script>
  import HeaderContent from "../Documentation/HeaderContent.svelte";
  import Divider from "components/Common/Divider.svelte";
  import Swagger from "components/Swagger.svelte";
  import MenuWrapper from "../Documentation/MenuWrapper.svelte";
  import TabContainer from "components/Common/Tabs/TabContainer.svelte";

  import { TABS_INFO_DOCUMENTATION } from "constants/tabs";
  import descriptionsInfo from "../../../docs/descriotionsInfo.json";
  import methodsInfo from "../../../docs/methodsInfo.json";
  import tlsInfo from "../../../docs/tlsInfo.json";
  import errorsInfo from "../../../docs/errorsInfo.json";

  let isLeftMenuOpen = false;
  let isRightMenuOpen = false;

  const toggleLeftMenu = () => {
    isRightMenuOpen = false;
    isLeftMenuOpen = !isLeftMenuOpen;
  };

  const toggleRightMenu = () => {
    isLeftMenuOpen = false;
    isRightMenuOpen = !isRightMenuOpen;
  };

  const setFalse = () => {
    isLeftMenuOpen = false;
    isRightMenuOpen = false;
  };
  const contentInfo = [
    { content: descriptionsInfo, tabInfo: TABS_INFO_DOCUMENTATION.description },
    { content: tlsInfo, tabInfo: TABS_INFO_DOCUMENTATION.tls },
    { content: methodsInfo, tabInfo: TABS_INFO_DOCUMENTATION.methods },
    null,
    { content: errorsInfo, tabInfo: TABS_INFO_DOCUMENTATION.errors }
  ];

  window.scrollTo(0, 0);
</script>

<style>
  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 24px;
  }
  .tabs-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #fff;
  }
  .tab-text {
    padding: 15px 30px;
    user-select: none;
  }
  .documentation-title {
    font-size: 40px;
    font-weight: 500;
    margin-bottom: 12px;
    color: #000;
  }
  @media (max-width: 992px) {
    .tabs-container {
      position: sticky;
      top: 70px;
      z-index: 999;
    }
  }
  @media (max-width: 768px) {
    .tab-text {
      font-size: 14px;
      padding: 14px 24px;
    }
    .tabs-container {
      position: sticky;
      top: 50px;
      z-index: 999;
    }
  }
</style>

<div>
  <HeaderContent title="Документация" {setFalse} />
  <Divider />
  <TabContainer>
    {#each contentInfo as content, index}
      {#if index === 3}
        <Swagger />
      {:else}
        <MenuWrapper
          tabInfo={content && content.tabInfo}
          menuContent={content && content.content}
          {isLeftMenuOpen}
          {isRightMenuOpen}
          {setFalse}
          {toggleLeftMenu}
          {toggleRightMenu}
          {index} />
      {/if}
    {/each}
  </TabContainer>
</div>
