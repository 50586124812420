<script>
  import ContactInfo from "./FormWrapper/ContactInfo.svelte";
  import PersonalInfo from "./FormWrapper/PersonalInfo.svelte";
  import ServicesInfo from "./FormWrapper/ServicesInfo.svelte";

  export let form;
</script>

<style>
  .inputs {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 24px;
    flex: 1;
  }
  :global(.inputs > div:not(:last-child)) {
    margin-right: 20px;
  }
  .service-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-bottom: 28px;
    flex: 1;
  }
</style>

<div class="inputs">
  <PersonalInfo {form} />
</div>
<div class="inputs">
  <ContactInfo />
</div>
<div class="service-container">
  <ServicesInfo {form} />
</div>
