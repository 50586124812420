/**
 * Вычесление позиции активного таба
 * @param reel - Нода родительского контейнера
 * @param indicator - Нода индикатора таба
 * @param tabs - Ноды табов
 * @param activeTabIndex - Индекс активного таба
 */
export const calculatePositionActiveTab = (
  reel,
  indicator,
  tabs,
  activeTabIndex
) => {
  const {
    transformCount,
    activeTabWidth,
    activeTabOffsetLeft
  } = getParamsToCalculatePosition(tabs, activeTabIndex);
  setIndicatorToTabPosition(
    reel,
    indicator,
    activeTabIndex,
    activeTabOffsetLeft,
    activeTabWidth
  );
  changeTabPosition(reel, activeTabIndex, transformCount);
};

/**
 * Изменение позиции таба
 * @param reel - Нода родительского контейнера
 * @param activeTabIndex - Индекс активного таба
 * @param transformCount - Значение смещения
 */
export const changeTabPosition = (reel, activeTabIndex, transformCount) => {
  reel.children[activeTabIndex].style.height = "auto";
  reel.children[activeTabIndex].style.overflow = "inherit";
  reel.children[activeTabIndex].style["-ms-overflow-style"] =
    "-ms-autohiding-scrollbar";

  reel.style.transform = `translateX(${activeTabIndex * -100}%)`;
};
/**
 * Скрытие неактивного таба
 * @param reel - Нода родительского контейнера
 * @param oldActiveIndex - Предыдущий актвный таб
 */
export const deleteIndicator = (reel, oldActiveIndex) => {
  reel.children[oldActiveIndex].style.height = "0";
  reel.children[oldActiveIndex].style.overflow = "hidden";
  reel.children[oldActiveIndex].style["-ms-overflow-style"] = "hidden";
  reel.children[oldActiveIndex].classList.remove("active-tab");
};

/**
 * Установление индикатора на позицию активного таба
 * @param reel - Нода родительского контейнера
 * @param indicator - Нода индикатора
 * @param activeTabIndex - Индекс активного таба
 * @param activeTabOffsetLeft - Расстоние слева до активного таба
 * @param activeTabWidth - Ширина активного таба
 */
export const setIndicatorToTabPosition = (
  reel,
  indicator,
  activeTabIndex,
  activeTabOffsetLeft,
  activeTabWidth
) => {
  reel.children[activeTabIndex].classList.add("active-tab");
  indicator.style.left = `${activeTabOffsetLeft}px`;
  indicator.style.width = `${activeTabWidth}px`;
};
/**
 * Получение параметров активного таба для изменения индикатора и активного таба
 * @param tabs - Ноды табов
 * @param activeTabIndex - Индекс активного таба
 */
export const getParamsToCalculatePosition = (tabs, activeTabIndex) => {
  let tabChildLength = tabs.children.length - 1;
  let transformCount = 100 / tabChildLength;
  let index = activeTabIndex || 0;
  let activeTabWidth = Array.from(tabs.children)[index].clientWidth;
  let activeTabOffsetLeft = Array.from(tabs.children)[index].offsetLeft;
  return { transformCount, activeTabWidth, activeTabOffsetLeft };
};
/**
 * Установление таба на активную позицию
 * @param reel - Нода родительского контейнера
 * @param indicator - Нода индикатора таба
 * @param tabs - Ноды табов
 * @param activeTabIndex - Индекс активного таба
 */
export const getParamsAndSetActiveTabPosition = (
  reel,
  indicator,
  tabs,
  activeTabIndex
) => {
  const { activeTabWidth, activeTabOffsetLeft } = getParamsToCalculatePosition(
    tabs,
    activeTabIndex
  );
  setIndicatorToTabPosition(
    reel,
    indicator,
    activeTabIndex,
    activeTabOffsetLeft,
    activeTabWidth
  );
};

export const addEvent = (name, el, func) => {
  if (el.addEventListener) {
    el.addEventListener(name, func, false);
  } else if (el.attachEvent) {
    el.attachEvent("on" + name, func);
  } else {
    el[name] = func;
  }
};

export const polyfill = () => {
  return (function() {
    var lastTime = 0;
    var vendors = ["ms", "moz", "webkit", "o"];
    for (var x = 0; x < vendors.length && !window.requestAnimationFrame; ++x) {
      window.requestAnimationFrame =
        window[vendors[x] + "RequestAnimationFrame"];
      window.cancelAnimationFrame =
        window[vendors[x] + "CancelAnimationFrame"] ||
        window[vendors[x] + "CancelRequestAnimationFrame"];
    }

    if (!window.requestAnimationFrame)
      window.requestAnimationFrame = function(callback, element) {
        var currTime = new Date().getTime();
        var timeToCall = Math.max(0, 16 - (currTime - lastTime));
        var id = window.setTimeout(function() {
          callback(currTime + timeToCall);
        }, timeToCall);
        lastTime = currTime + timeToCall;
        return id;
      };

    if (!window.cancelAnimationFrame)
      window.cancelAnimationFrame = function(id) {
        clearTimeout(id);
      };
  })();
};
