<script>
  import LeftHeader from "./BottomHeader/LeftHeader.svelte";
  import RightHeader from "./BottomHeader/RightHeader.svelte";
  import ContentWrapper from "components/ContentWrapper.svelte";

  export let newsInfo;
</script>

<style>
  .sub-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 24px 0 50px;
  }
  :global(.sub-header > div) {
    max-width: 1000px;
  }
  @media (max-width: 767px) {
    .sub-header {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      margin: 0;
    }
  }
  @media (max-width: 992px) {
    :global(.sub-header > div) {
      max-width: 550px;
    }
  }
</style>

<div class="w-100 max-width">
  <ContentWrapper>
    <div class="sub-header">
      <LeftHeader />
      <RightHeader {newsInfo} />
    </div>
  </ContentWrapper>
</div>
