<script>
  import { onMount, onDestroy, tick } from "svelte";
  import { navigate } from "svelte-routing";
  import { changeMenuDuringScroll } from "utils/scrollUtils";
  import { getValueLocationParam } from "utils/urlUtils";
  import { activeTabInDocumentation } from "../../../store";
  export let selectedCase;
  export let isRightMenuOpen;
  export let setFalse;
  export let tabInfo;
  onMount(() => {
    window.addEventListener("scroll", changeMenuDuringScroll());
    window.addEventListener("resize", changeMenuDuringScroll());
    scrollTo();
  });

  $: scrollTo($activeTabInDocumentation);

  async function scrollTo() {
    await tick();
    const links = document.querySelectorAll(
      `a[href*='#']:not([target="_blank"])`
    );
    links.forEach(each => (each.onclick = scrollAnchors));
  }

  const scrollToHash = ({ self, link }) => {
    const width = document.documentElement.clientWidth;
    let positionTop = width <= 992 ? 200 : 100;
    const distanceToTop = el =>
      el && Math.floor(el.getBoundingClientRect().top);
    const targetID =
      decodeURIComponent(self.hash) || decodeURIComponent(link.hash);
    let targetAnchor =
      targetID && document.getElementById(targetID.replace("#", ""));
    if (targetAnchor) {
      const originalTop = distanceToTop(targetAnchor);
      window.scrollBy({
        top: originalTop - positionTop,
        left: 0,
        behavior: "smooth"
      });
    }
    const checkIfDone = setInterval(function() {
      const atBottom =
        window.innerHeight + window.pageYOffset >=
        document.body.offsetHeight - 2;
      if (distanceToTop(targetAnchor) === 0 || atBottom) {
        window.history.replaceState("", "", targetID);
        clearInterval(checkIfDone);
      }
    }, 100);
  };

  function scrollAnchors(e) {
    setFalse();
    e.preventDefault();
    e.stopPropagation();

    let link = e.target;
    if (
      link.nodeName === "SPAN" ||
      link.nodeName === "CODE" ||
      link.nodeName === "IMG" ||
      link.nodeName === "SVG"
    ) {
      link = link.parentNode;
    }

    const tabs = +getValueLocationParam(link).tab || 0;
    const categoryIndex = +getValueLocationParam(link).categoryIndex || 0;
    const dataIndex = +getValueLocationParam(link).dataIndex || 0;
    const activeTab = +getValueLocationParam(location).tab || 0;
    if (link.pathname !== location.pathname) {
      navigate(link);
    } else {
      activeTab !== tabs && window.slideToTab(tabs, categoryIndex, dataIndex)();
    }
    const self = this;
    scrollToHash({ self, link });
    return false;
  }

  onDestroy(() => {
    window.removeEventListener("scroll", changeMenuDuringScroll);
    window.removeEventListener("resize", changeMenuDuringScroll);
  });
</script>

<style>
  .wrapper-menu-content {
    align-self: flex-start;
    display: block;
    flex: 0 0 245px;
    max-height: calc(100vh - 90px);
    overflow-y: auto;
    position: -webkit-sticky;
    position: sticky;
    top: 30px;
    margin-bottom: 16px;
  }
  .wrapper-menu-content::-webkit-scrollbar {
    width: 5px;
  }
  .menu-list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
  }
  .menu-item-wrapper {
    position: relative;
    width: 100%;
  }
  .menu-item a {
    transition: all 300ms;
    color: #2e3e56;
    padding-right: 6px;
  }
  .menu-item:hover a {
    color: #0056d6;
  }
  .menu-item-active a {
    color: #0056d6;
    display: inline-flex;
    align-items: center;
    padding-right: 4px;
    border-left: 2px solid rgb(0, 114, 229);
    box-sizing: border-box;
    font-weight: 800;
  }

  .header-wrapper {
    display: flex;
    align-items: center;
    width: 100%;
    font-size: 13px;
    line-height: 16px;
  }
  .header-menu {
    font-weight: 600;
    font-size: 13px;
    color: rgba(46, 62, 86, 0.5);
    border-left: 2px solid transparent;
    padding: 4px 12px 4px 12px;
    width: 100%;
    margin-bottom: 4px;
    text-decoration: none;
    letter-spacing: -0.5px;
    white-space: nowrap;
  }
  .header-menu:hover {
    color: #0074c6;
    cursor: pointer;
  }
  .small-menu {
    position: sticky;
    top: 60px;
    flex: 0 0 220px;
    /*min-width: 250px;*/
    /*max-width: 350px;*/
    border-left: 1px solid #e0e0e0;
    padding: 8px 0 4px 4px;
    margin-top: 24px;
    align-self: flex-start;
  }
  :global(.anchor) {
    padding-right: 10px;
    margin-left: -10px;
  }
  :global(.icon-viewer) {
    opacity: 0;
    transition: opacity 450ms;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-width: 20px;
  }
  :global(a > img) {
    vertical-align: text-bottom;
  }
  :global(h5:hover > a > .icon-viewer, h4:hover > a > .icon-viewer, h3:hover > a > .icon-viewer, h2:hover
      > a
      > .icon-viewer, h1:hover > a > .icon-viewer) {
    opacity: 1;
  }
  :global(h3:hover > a:hover, h2:hover > a:hover, h1:hover > a:hover) {
    text-decoration: none;
  }
  @media (max-width: 1200px) {
    .wrapper-menu-content {
      flex: 0 0 200px;
    }
  }
  @media (max-width: 992px) {
    .wrapper-menu-content {
      display: none;
      top: 200px !important;
    }
    .open {
      background: #fff;
      width: 100%;
      z-index: 999;
      height: 100%;
    }
    .open.wrapper-menu-content {
      max-height: unset;
      overflow-y: auto;
      top: 105px;
      display: block;
      flex: unset;
    }
  }
</style>

<div class="wrapper-menu-content {isRightMenuOpen && 'open'}">
  {#if selectedCase}
    {#each selectedCase.headers as { title, link }, i}
      <div class="menu-list">
        <div id="main-menu" class="menu-item-wrapper">
          <li
            class="header-wrapper level1 {i === 0 && 'menu-item-active'}"
            data-id={i}
            data-href={link}>
            <a class="header-menu" href={link}>{title}</a>
          </li>
        </div>
      </div>
    {/each}
  {/if}
</div>
