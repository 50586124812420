<script>
  import { onMount } from "svelte";
  import List from "components/Common/List/List.svelte";
  import ServiceButton from "components/Common/Buttons/ServiceButton.svelte";
  import Popover from "components/Common/Popover.svelte";
  import DotsIcon from "static/DotsIcon.svelte";

  const checkMobileText = () => {
    const width = document.documentElement.clientWidth;
    if (width >= 768) return "Все сервисы";
    else return "";
  };

  let text = checkMobileText();

  onMount(() => {
    window.addEventListener("resize", () => {
      text = checkMobileText();
    });
  });
</script>

<style>
  .button-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .button-icon {
    width: 24px;
    height: 24px;
    margin-right: 8px;
  }
  .text-button {
    white-space: nowrap;
  }
  @media (max-width: 1199px) {
    :global(div.container-padding.white-button-container) {
      padding: 4px 12px;
    }
    :global(.button-base-root.button-root.button-container.button-paddings) {
      padding: 8px 12px;
    }
  }
  @media (max-width: 992px) {
    .button-container {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    :global(div.container-padding.white-button-container) {
      padding: 4px 12px;
    }
    :global(.button-base-root.button-root.button-container.button-paddings) {
      padding: 8px 12px;
    }
  }
  @media (max-width: 768px) {
    .button-container {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .button-icon {
      width: 24px;
      height: 24px;
      margin-right: 0;
    }
    :global(.button-base-root.button-root.button-container.button-paddings) {
      padding: 6px;
      border-radius: 12px;
    }
  }
</style>

<Popover let:active>
  <div slot="button">
    <ServiceButton
      {active}
      buttonClassName="button-paddings"
      className="container-padding">
      <div class="button-container">
        <div class="button-icon">
          <DotsIcon />
        </div>
        <div class="text-button">{text}</div>
      </div>
    </ServiceButton>
  </div>
  <div slot="content">
    <List />
  </div>
</Popover>
