<script>
  import Login from "static/Login.svelte";
  import HumburgerButton from "components/Common/Buttons/HumburgerButton.svelte";
  import { MENU_LINKS } from "constants/links";
  import Logo from "static/Logo.svelte";
  import Divider from "components/Common/Divider.svelte";
  import MenuItem from "components/Common/MobileMenuItem.svelte";
  export let getProps;
</script>

<style>
  .title {
    color: #000;
    padding: 15px 24px;
  }
  .menu {
    margin-top: 10px;
  }
  .wrapper-lk {
    display: flex;
    align-items: center;
    text-decoration: none;
    cursor: pointer;
    padding: 15px 24px;
  }
  .wrapper-lk:hover .link-lk {
    opacity: 0.8;
  }
  .text-lk {
    font-size: 16px;
    color: #2e3e56;
    opacity: 0.5;
    margin-right: 6px;
  }
  .link-lk {
    text-decoration: none;
    color: #2e3e56;
    font-size: 16px;
  }
  .icon-lk-menu {
    width: 24px;
    height: 24px;
    margin-left: 8px;
    display: flex;
    align-items: center;
  }
  :global(.icon-lk-menu > svg) {
    width: 24px;
    height: 24px;
  }
  @media (max-width: 767px) {
    .text-lk {
      font-size: 14px;
    }
    .link-lk {
      font-size: 14px;
    }
    .menu {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
    }
  }
</style>

<HumburgerButton color="#fff">
  <div class="title">Меню</div>
  <Divider />
  <div class="menu">
    {#each MENU_LINKS as menu, i}
      <MenuItem list={menu} {getProps} />
    {/each}
  </div>
  <Divider />
  <a class="wrapper-lk" href="https://platform.astral.ru/lk">
    <div class="text-lk">Войти в</div>
    <div class="link-lk">Личный кабинет</div>
    <div class="icon-lk-menu">
      <Login />
    </div>
  </a>
</HumburgerButton>
