<script>
  import ListItem from "./ListItem.svelte";
  import serviceInfo from "services/Info.json";
  let allService = Object.values(serviceInfo);
</script>

<style>
  .list {
    width: 100%;
    max-width: 360px;
    max-height: 45vh;
    overflow-y: auto;
    margin: 12px 0;
  }
</style>

<div class="list">
  {#each allService as service, i}
    <ListItem {service} />
  {/each}
</div>
