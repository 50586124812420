import { writable } from "svelte/store";

/**
 * Стор по активным сервисам
 */
const SelectActiveService = () => {
  const { subscribe, set, update } = writable({});

  return {
    subscribe,
    setActiveService: data => update(services => (services = data))
  };
};

export const activeService = SelectActiveService();

/**
 * Стор по активным табам в сервисе
 */
const SelectActiveTab = () => {
  const { subscribe, set, update } = writable(0);

  return {
    subscribe,
    setActiveTab: data => update(tab => (tab = data))
  };
};

export const activeTab = SelectActiveTab();

/**
 * Стор по активному документу в сервисе
 */
const SelectActiveDoc = () => {
  const { subscribe, set, update } = writable([]);

  return {
    subscribe,
    setActiveDoc: data => update(doc => (doc = data))
  };
};

export const activeDoc = SelectActiveDoc();

/**
 * Стор по активным табам в документации
 */
const SelectActiveTabInDocumentation = () => {
  const { subscribe, set, update } = writable(0);

  return {
    subscribe,
    setActiveTabInDocumentation: data => update(tab => (tab = data))
  };
};

export const activeTabInDocumentation = SelectActiveTabInDocumentation();

/**
 * Стор по активному документу в документации
 */
const SelectActiveDocInDocumentation = () => {
  const { subscribe, set, update } = writable({});

  return {
    subscribe,
    setActiveDocInDocumentation: data => update(doc => (doc = data))
  };
};

export const activeDocInDocumentation = SelectActiveDocInDocumentation();

/**
 * Стор по выбранной новости
 */
const SelectActiveNews = () => {
  const { subscribe, set, update } = writable({});

  return {
    subscribe,
    setActiveNews: data => update(news => (news = data))
  };
};

export const activeNews = SelectActiveNews();

/**
 * Стор по выбранным сервисам в заявке
 */
const SelectIntegrationService = () => {
  const { subscribe, set, update } = writable([]);

  return {
    subscribe,
    setSelectService: data => update(services => (services = data))
  };
};

export const selectedServices = SelectIntegrationService();
