import Inputmask from "inputmask";

const registered = {};

const registerField = (input, form) => {
  const { name } = input;
  form.registerField(
    name,
    fieldState => {
      const { blur, change, error, focus, touched, value } = fieldState;
      const errorElement = document.getElementById(name + "_error");
      if (!registered[name]) {
        input.addEventListener("blur", () => blur());
        input.addEventListener("input", event => {
          const caret = event.target.selectionStart;
          const element = event.target;
          window.requestAnimationFrame(() => {
            if (element.selectionStart) {
              element.selectionStart = caret;
              element.selectionEnd = caret;
            }
          });
          if (name === "services") {
            input.value = event.target.value.trim();
          } else
            change(
              input.type === "checkbox"
                ? event.target.checked
                : event.target.value.trim()
            );
        });
        input.addEventListener("change", event => {
          const caret = event.target.selectionStart;
          const element = event.target;
          window.requestAnimationFrame(() => {
            if (element.selectionStart) {
              element.selectionStart = caret;
              element.selectionEnd = caret;
            }
          });
          if (name === "services") input.value = "";
          change(
            input.type === "checkbox"
              ? event.target.checked
              : event.target.value.trim()
          );
        });
        input.addEventListener("focus", focus);
        registered[name] = true;
      }
      if (input.type === "checkbox") {
        input.checked = value;
      } else {
        if (name === "phone_mobile") {
          Inputmask({
            mask: "+7(999)999-99-99",
            showMaskOnHover: false,
            oncleared: () => change(undefined)
          }).mask(input);
          input.value = value === undefined ? "" : value;
        } else if (name === "services") {
        } else {
          input.value = value === undefined ? "" : value;
        }
      }

      const setError = ({ name, input, errorElement, error }) => {
        if (input.type === "hidden") return;
        if (name === "services") input.parentNode.classList.add("error-field");
        else input.classList.add("error-field");
        errorElement.innerHTML = error;
        errorElement.style.display = "block";
      };

      const resetError = () => {
        if (input.type === "hidden") return;
        if (name === "services")
          input.parentNode.classList.remove("error-field");
        else input.classList.remove("error-field");
        errorElement.innerHTML = "";
        errorElement.style.display = "none";
      };

      if (errorElement) {
        if (touched && error) {
          setError({ name, input, errorElement, error });
        } else {
          resetError({ name, input, errorElement });
        }
      }
    },
    {
      value: true,
      error: true,
      touched: true
    }
  );
};

export default registerField;
