<script>
  export let value;
  export let activeTabIndex;
  export let slideToTab;
</script>

<style>
  .tab {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  .tab:hover {
    background-color: rgba(0, 86, 214, 0.06);
    color: #0074c6;
  }
  .activeTab {
    color: #0056d6;
  }
</style>

<div
  class="{activeTabIndex === value && 'activeTab'} tab"
  on:click={slideToTab(value)}>
  <slot />
</div>
