<script>
  import { onMount, afterUpdate } from "svelte";
  import ClipboardJS from "clipboard";
  import TextWrapper from "components/TextWrapper.svelte";
  import Prism from "prismjs";
  import { getValueLocationParam } from "utils/urlUtils";

  export let selectedCase;
  export let isLeftMenuOpen;
  export let isRightMenuOpen;
  export let tabInfo;
  let isMenuOpen = isRightMenuOpen || isLeftMenuOpen;
  let btn;
  let btnLength;
  let counter = 0;
  let images = [];
  onMount(() => {
    images = Array.from(document.querySelectorAll("img"));
    if (images.length)
      images.forEach(
        image =>
          (image.onload = () => {
            counter++;
            if (images.length === counter) {
              scrollToHash();
            }
          })
      );
  });
  const scrollToHash = () => {
    let hashEl = document.getElementById(
      decodeURIComponent(location.hash).replace("#", "")
    );
    if (hashEl) {
      const width = document.documentElement.clientWidth;
      let positionTop = width <= 992 ? 200 : 100;
      const distanceToTop = el =>
        el && Math.floor(el.getBoundingClientRect().top);
      const originalTop = distanceToTop(hashEl);
      window.scrollBy({
        top: originalTop - positionTop,
        left: 0,
        behavior: "smooth"
      });
    }
  };
  afterUpdate(() => {
    btn = document.querySelectorAll(".btnClipboard");
    addCopyButtonToCode();
    const locationTab = +getValueLocationParam(location).tab || 0;
    if (locationTab === tabInfo.index) scrollToHash();
  });
  const addCopyButtonToCode = () => {
    Prism.highlightAll();
    let allCodeBlocksElements = document.querySelectorAll(".code-toolbar");
    allCodeBlocksElements.forEach((el, index) => {
      let currentId = "codeblock" + index;
      if (!el.querySelector("div>.btnClipboard")) {
        el.style.position = "relative";
        let code = el.querySelector("pre>code");
        code.setAttribute("id", currentId);
        let frag = document.createElement("div");
        frag.innerHTML = `<button class="btnIcon btnClipboard copybtn" data-clipboard-target="#${currentId}" type="button" aria-label="Скопировать"><div class="btnIcon__body"><svg width="12" height="12" viewBox="340 364 14 15" xmlns="http://www.w3.org/2000/svg"><path fill="currentColor" d="M342 375.974h4v.998h-4v-.998zm5-5.987h-5v.998h5v-.998zm2 2.994v-1.995l-3 2.993 3 2.994v-1.996h5v-1.995h-5zm-4.5-.997H342v.998h2.5v-.997zm-2.5 2.993h2.5v-.998H342v.998zm9 .998h1v1.996c-.016.28-.11.514-.297.702-.187.187-.422.28-.703.296h-10c-.547 0-1-.452-1-.998v-10.976c0-.546.453-.998 1-.998h3c0-1.107.89-1.996 2-1.996 1.11 0 2 .89 2 1.996h3c.547 0 1 .452 1 .998v4.99h-1v-2.995h-10v8.98h10v-1.996zm-9-7.983h8c0-.544-.453-.996-1-.996h-1c-.547 0-1-.453-1-.998 0-.546-.453-.998-1-.998-.547 0-1 .452-1 .998 0 .545-.453.998-1 .998h-1c-.547 0-1 .452-1 .997z" fill-rule="evenodd"></path></svg><strong class="btnIcon__label">Копировать</strong></div></button>`;
        let text = frag.querySelector(".btnIcon__label");
        if (el) {
          frag.onclick = e => {
            text.innerHTML = "Скопировано";
            setTimeout(() => {
              text.innerHTML = "Копировать";
            }, 1500);
          };
          el.appendChild(frag);
        }
      }
    });
    let clip = new ClipboardJS(".btnClipboard");
    clip.on("success", function(e) {
      e.clearSelection();
    });
    clip.on("error", function(e) {
      console.log(e);
    });
  };
</script>

<style>
  .content-wrapper {
    flex: 1 auto;
    max-width: 100%;
    min-width: 0;
  }
  .content-markdown {
    width: 100%;
    height: 100%;
    font-size: 15px;
    padding: 0 12px;
    line-height: 21px;
    font-weight: 500;
  }
  :global(.content-markdown h2) {
    font-size: 22px;
    margin-bottom: 8px;
    margin-top: 0;
    line-height: initial;
  }
  :global(.content-markdown p) {
    margin: 8px 0;
    position: relative;
  }
  :global(.content-markdown p .tooltip) {
    position: absolute;
    display: inline-block;
    margin-left: 4px;
  }

  :global(.content-markdown h2:not(:first-of-type)) {
    margin-top: 32px;
  }
  :global(.content-markdown > pre) {
    border-radius: 24px;
    display: grid;
    font-size: 12px !important;
  }
  :global(.content-markdown > pre > code) {
    display: block;
    width: 100%;
    overflow-y: auto;
    overflow-x: auto;
    padding: 10px;
  }
  :global(.content-markdown > h2 > a) {
    margin-left: -20px;
  }
  :global(.content-markdown > ol, content-markdown > ul) {
    padding: 0;
    margin: 4px 0;
  }
  :global(.content-markdown p > img) {
    max-width: 100%;
    margin: auto;
    display: block;
  }
  :global(.content-markdown table) {
    display: block;
    width: max-content;
    max-width: 100%;
    overflow: auto;
    font-size: 12px;
    margin: auto;
  }

  .tooltip {
    position: relative;
    display: inline-block;
    border-bottom: 1px dotted black; /* If you want dots under the hoverable text */
  }

  /* Tooltip text */
  :global(.content-markdown .tooltip .tooltiptext) {
    opacity: 0;
    background-color: #555;
    color: #fff;
    text-align: center;
    padding: 4px 8px;
    border-radius: 6px;
    width: max-content;
    position: absolute;
    font-size: 12px;
    z-index: 1;
    transition: opacity ease-in-out 250ms;
  }

  /* Show the tooltip text when you mouse over the tooltip container */
  :global(.tooltip:hover .tooltiptext) {
    opacity: 1;
  }

  :global(.content-markdown tt) {
    padding: 0.2em 0.4em;
    margin: 0;
    font-size: 85%;
    background-color: rgba(27, 31, 35, 0.05);
    border-radius: 6px;
  }

  :global(.content-markdown table tr) {
    background-color: #fff;
    border-top: 1px solid #c6cbd1;
  }
  :global(.content-markdown table td, .content-markdown table th) {
    padding: 4px 8px;
    border: 1px solid #dfe2e5;
    white-space: nowrap;
  }
  :global(.content-markdown table tr:nth-child(2n)) {
    background-color: #f6f8fa;
  }
  :global(.content-markdown table th) {
    font-weight: 600;
  }

  :global(.content-markdown > ol > li) {
    margin-left: 14px;
  }
  :global(.content-markdown > .code-toolbar) {
    display: grid;
    font-size: 12px !important;
  }
  :global(.content-markdown > :last-child) {
    margin-bottom: 0 !important;
  }
  :global(.content-markdown details) {
    margin-top: 0;
    margin-bottom: 16px;
  }
  :global(.content-markdown details > summary) {
    cursor: pointer;
  }
  :global(.content-markdown details > summary:focus) {
    outline: none;
    box-shadow: none;
  }
  @media (max-width: 1200px) {
    .open {
      visibility: hidden;
    }
  }
  @media (max-width: 768px) {
    :global(.content-markdown > h2) {
      font-size: 20px;
    }
  }
</style>

<div class="content-wrapper {(isRightMenuOpen || isLeftMenuOpen) && 'open'}">
  <TextWrapper>
    <div class="content-markdown scroll">
      {@html selectedCase.content}
    </div>
  </TextWrapper>
</div>
