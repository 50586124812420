/**
 * Описание табов для меню навигации по табам
 */
export let TABS_INFO_CONTENT = [
  {
    name: "description",
    title: "Описание"
  },
  {
    name: "cases",
    title: "Сценарии работы"
  },
  {
    name: "structure",
    title: "Структура событий"
  }
];

/**
 * Описание табов для меню навигации по табам
 */
export let TABS_INFO_DOCUMENTATION = {
  description: {
    index: 0,
    name: "Описание"
  },
  tls: {
    index: 1,
    name: "Настройка TLS"
  },
  methods: {
    index: 2,
    name: "Методы"
  },
  swagger: {
    index: 3,
    name: "Спецификация API"
  },
  errors: {
    index: 4,
    name: "Ошибки"
  }
};
