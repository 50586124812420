import axios from "axios";

const onSubmit = async values => {
  const { phone_mobile, FIO, email, organization, services } = values;
  return await axios.post("/api/sendEmail", {
    phone: phone_mobile,
    FIO,
    email,
    organization,
    services
  });
};

export default onSubmit;
