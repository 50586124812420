<script>
  const year = new Date().getFullYear();
</script>
<style>
  .text-helper {
    color: #fff;
    opacity: 0.5;
    font-size: 14px;
    margin-bottom: 10px;
  }
  .link {
    margin-left: 16px;
    opacity: 0.7;
  }
  .link:hover {
    opacity: 1;
  }
  .link a {
    text-decoration: none;
    color: #fff;
    font-size: 14px;
  }
  .contact {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
  .copyright {
    margin: 0;
  }
</style>

<div class="contact">
  <div class="text-helper copyright">
    © 1993-{year}, ГК&nbsp;"Астрал"
  </div>
  <div class="link">
    <a href="https://astral.ru">astral.ru</a>
  </div>
</div>
