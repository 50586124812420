<script>
  import { navigate } from "svelte-routing";
  import Button from "components/Common/Buttons/Button.svelte";

  export let placeholderTitle;
  export let placeholderText;
  export let PlaceholderImage;
  const handleClick = () => {
    navigate("/");
  };
</script>

<style>
  .placeholder-wrapper {
    display: flex;
    height: 100%;
  }
  .placeholder-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    flex: 1;
  }
  .placeholder-image {
    margin-bottom: 30px;
  }
  .placeholder-title {
    width: 50%;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 10px;
    text-align: center;
  }
  .placeholder-text {
    width: 50%;
    font-size: 16px;
    word-break: break-word;
    text-align: center;
  }
  @media (max-width: 768px) {
    .placeholder-title {
      width: 100%;
    }
    .placeholder-text {
      width: 100%;
    }
  }
  @media (max-width: 992px) {
    .placeholder-title {
      width: 75%;
    }
    .placeholder-text {
      width: 75%;
    }
  }
</style>

<div class="placeholder-wrapper">
  <div class="placeholder-container">
    <div class="placeholder-image">
      <PlaceholderImage />
    </div>
    {#if placeholderTitle}
      <div class="placeholder-title">{placeholderTitle}</div>
    {/if}
    {#if placeholderText}
      <div class="placeholder-text">{placeholderText}</div>
    {/if}
    <Button contained onClick={handleClick}>На главную</Button>
  </div>
</div>
