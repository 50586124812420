<script>
  import { onMount } from "svelte";
  import { navigate } from "svelte-routing";
  import {
    activeDocInDocumentation,
    activeTabInDocumentation
  } from "../../store";
  import ServicePopover from "components/Common/ServicePopover.svelte";
  import Tabs from "components/Common/Tabs/Tabs.svelte";
  import ContentWrapper from "components/ContentWrapper.svelte";
  import Tab from "components/Common/Tabs/Tab.svelte";
  import { setDocUrl, replaceSwaggerHash } from "utils/urlUtils";

  import { TABS_INFO_DOCUMENTATION } from "constants/tabs";

  export let name;
  export let title;
  export let icon;
  export let setFalse;
  const checkMobile = () => {
    const width = document.documentElement.clientWidth;
    return width <= 988;
  };

  let isMobile = checkMobile();

  onMount(() => {
    window.addEventListener("resize", () => {
      isMobile = checkMobile();
    });
  });

  const tabsInfo = Object.values(TABS_INFO_DOCUMENTATION);

  const onChange = ({ newTabIndex, categoryIndex, dataIndex, typeEvent }) => {
    setFalse();
    let url = "";
    let activeDocInTab = $activeDocInDocumentation[newTabIndex];
    activeTabInDocumentation.setActiveTabInDocumentation(newTabIndex);
    if (Number.isInteger(dataIndex)) {
      if (activeDocInTab && activeDocInTab.dataIndex === dataIndex) {
        activeDocInDocumentation.setActiveDocInDocumentation({
          ...$activeDocInDocumentation,
          ...activeDocInTab
        });
      } else {
        activeDocInDocumentation.setActiveDocInDocumentation({
          ...$activeDocInDocumentation,
          [+newTabIndex]: { dataIndex }
        });
      }
      activeDocInTab = $activeDocInDocumentation[newTabIndex];
      url = setDocUrl({ tab: +newTabIndex, dataIndex, ...activeDocInTab });
    } else url = setDocUrl({ tab: +newTabIndex, ...activeDocInTab });
    typeEvent !== "popstate" && navigate(url);
  };
</script>

<style>
  .container {
    padding-top: 42px;
  }
  .container-info-icon {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
  }
  .content-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #0056d6;
    border-radius: 50%;
    padding: 24px;
    margin: 0 36px 12px 0;
  }
  :global(.content-icon svg) {
    fill: #fff;
  }
  .content-info-text {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
  .content-title {
    color: #2e3e56;
    font-weight: 500;
    font-size: 40px;
    margin-left: 28px;
  }
  .content-buttons-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }
  .tab-text {
    padding: 15px 30px;
    user-select: none;
  }
  .sticky {
    position: sticky;
    top: 70px;
    background: #fff;
    z-index: 999;
  }
  @media (max-width: 992px) {
    .container-info-icon {
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
    .content-icon {
      margin: 0;
    }
    :global(.content-icon svg) {
      width: 80px;
      height: 80px;
    }
    .content-info-text {
      align-items: center;
    }
    .content-title {
      font-size: 24px;
      text-align: center;
      padding: 4px 12px;
    }
    .content-buttons-wrapper {
      margin-top: 12px;
      justify-content: center;
      align-items: center;
    }
    .tab-text {
      padding: 15px 20px;
    }
  }
  @media (max-width: 768px) {
    .sticky {
      position: sticky;
      top: 50px;
    }
  }
</style>

<div class="max-width container">
  <ContentWrapper>
    <div class="container-info-icon">
      <div class="content-icon">
        <svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M37 42V16H29C27.8954 16 27 15.1046 27 14V6H11V42H37ZM29
            6.82843L36.1716 14H29V6.82843ZM29 4L39 14V43C39 43.5523 38.5523 44
            38 44H10C9.44772 44 9 43.5523 9 43V5C9 4.44771 9.44772 4 10 4H29ZM16
            20C15.4477 20 15 20.4477 15 21C15 21.5523 15.4477 22 16
            22H32C32.5523 22 33 21.5523 33 21C33 20.4477 32.5523 20 32 20H16ZM15
            27C15 26.4477 15.4477 26 16 26H32C32.5523 26 33 26.4477 33 27C33
            27.5523 32.5523 28 32 28H16C15.4477 28 15 27.5523 15 27ZM16
            32C15.4477 32 15 32.4477 15 33C15 33.5523 15.4477 34 16
            34H32C32.5523 34 33 33.5523 33 33C33 32.4477 32.5523 32 32 32H16Z" />
        </svg>
      </div>
      <div class="content-info-text">
        <div class="content-title">{title}</div>
        {#if !isMobile}
          <div class="content-buttons-wrapper">
            <Tabs
              let:activeTabIndex
              let:slideToTab
              {onChange}
              setStoreTab={activeTabInDocumentation.setActiveTabInDocumentation}>
              {#each tabsInfo as { name, index }, i}
                <Tab value={index} {activeTabIndex} {slideToTab}>
                  <div class="tab-text">{name}</div>
                </Tab>
              {/each}
            </Tabs>
            <ServicePopover />
          </div>
        {/if}
      </div>
    </div>
  </ContentWrapper>
</div>
{#if isMobile}
  <div class="content-buttons-wrapper sticky">
    <Tabs
      let:activeTabIndex
      let:slideToTab
      {onChange}
      setStoreTab={activeTabInDocumentation.setActiveTabInDocumentation}>
      {#each tabsInfo as { name, index }, i}
        <Tab value={index} {activeTabIndex} {slideToTab}>
          <div class="tab-text">{name}</div>
        </Tab>
      {/each}
    </Tabs>
    <ServicePopover />
  </div>
{/if}
