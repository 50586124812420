<script>
  import { onMount } from "svelte";
  import { navigate } from "svelte-routing";
  import { activeService, activeTab, activeDoc } from "../../store";
  import Menu from "components/Common/Menu/Menu.svelte";
  import { setServiceUrl, getValueLocationParam } from "utils/urlUtils";

  export let tabInfo;
  export let menuContent;
  export let isLeftMenuOpen;
  export let isRightMenuOpen;
  export let setFalse;
  export let toggleLeftMenu;
  export let toggleRightMenu;

  let nextTitle = "";
  let prevTitle = "";

  function getActiveDocInTab() {
    const sesStorActiveDoc = JSON.parse(sessionStorage.getItem("docPosition"));
    let newActiveDocInTab = {
      categoryIndex: +getValueLocationParam(location).categoryIndex || 0,
      dataIndex: +getValueLocationParam(location).dataIndex || 0,
      tab: +getValueLocationParam(location).tab || 0
    };
    const activeDocInfo = Object.keys($activeDoc);
    if (
      !activeDocInfo.length &&
      !!sesStorActiveDoc &&
      !!Object.keys(sesStorActiveDoc).length
    ) {
      activeDoc.setActiveDoc(sesStorActiveDoc);
    }
    if (
      !activeDocInfo.length ||
      !$activeDoc[$activeTab] ||
      $activeDoc[$activeTab].dataIndex !== newActiveDocInTab.dataIndex ||
      $activeDoc[$activeTab].categoryIndex !== newActiveDocInTab.categoryIndex
    ) {
      activeDoc.setActiveDoc({
        ...$activeDoc,
        [+newActiveDocInTab.tab]: {
          dataIndex: newActiveDocInTab.dataIndex,
          categoryIndex: newActiveDocInTab.categoryIndex
        }
      });
    }
    return $activeDoc[+$activeTab];
  }

  let activeDocInTab = getActiveDocInTab();

  onMount(() => {
    activeDocInTab = getActiveDocInTab();
  });

  const selectCase = activeDocInTab => {
    let selectedCase;
    if (menuContent) {
      if (
        menuContent.categories[activeDocInTab.categoryIndex] &&
        menuContent.categories[activeDocInTab.categoryIndex].data[
          activeDocInTab.dataIndex + 1
        ]
      )
        nextTitle =
          menuContent.categories[activeDocInTab.categoryIndex].data[
            activeDocInTab.dataIndex + 1
          ].title;
      else if (menuContent.categories[activeDocInTab.categoryIndex + 1])
        nextTitle =
          menuContent.categories[activeDocInTab.categoryIndex + 1].data[0]
            .title;
      else nextTitle = "";

      if (
        menuContent.categories[activeDocInTab.categoryIndex] &&
        menuContent.categories[activeDocInTab.categoryIndex].data[
          activeDocInTab.dataIndex - 1
        ]
      )
        prevTitle =
          menuContent.categories[activeDocInTab.categoryIndex].data[
            activeDocInTab.dataIndex - 1
          ].title;
      else if (menuContent.categories[activeDocInTab.categoryIndex - 1])
        prevTitle =
          menuContent.categories[activeDocInTab.categoryIndex - 1].data[
            menuContent.categories[activeDocInTab.categoryIndex - 1].data
              .length - 1
          ].title;
      else prevTitle = "";
      selectedCase =
        menuContent.categories[activeDocInTab.categoryIndex] &&
        menuContent.categories[activeDocInTab.categoryIndex].data[
          activeDocInTab.dataIndex
        ];
      if (!selectedCase)
        selectedCase =
          menuContent.categories[0] && menuContent.categories[0].data[0];
    }
    return selectedCase;
  };

  function getActiveDocInTabOrSetDefault() {
    if (!$activeDoc[+$activeTab]) {
      activeDoc.setActiveDoc({
        ...$activeDoc,
        [+$activeTab]: {
          categoryIndex: 0,
          dataIndex: 0
        }
      });
    }
    return $activeDoc[+$activeTab];
  }

  $: activeDocInTab = getActiveDocInTabOrSetDefault($activeDoc, $activeTab);
  let selectedCase = selectCase(activeDocInTab);
  $: selectedCase = selectCase(activeDocInTab);
  /**
   * Выбор документации по клику
   * @param parentId - номер категории
   * @param id - номер документации
   */
  const changeCase = ({ parentId, id }) => {
    navigate(
      setServiceUrl({
        name: $activeService.name,
        tab: $activeTab,
        categoryIndex: parentId,
        dataIndex: id
      })
    );
    activeDoc.setActiveDoc({
      ...$activeDoc,
      [+$activeTab]: {
        categoryIndex: parentId,
        dataIndex: id
      }
    });
    activeDocInTab = $activeDoc[+$activeTab];
    sessionStorage.setItem("docPosition", JSON.stringify($activeDoc));
    if (menuContent.categories[parentId].data[id + 1]) {
      nextTitle = menuContent.categories[parentId].data[id + 1].title;
    } else if (menuContent.categories[parentId + 1]) {
      nextTitle = menuContent.categories[parentId + 1].data[0].title;
    } else {
      nextTitle = "";
    }
    if (menuContent.categories[parentId].data[id - 1]) {
      prevTitle = menuContent.categories[parentId].data[id - 1].title;
    } else if (menuContent.categories[parentId - 1]) {
      prevTitle =
        menuContent.categories[parentId - 1].data[
          menuContent.categories[parentId - 1].data.length - 1
        ].title;
    } else {
      prevTitle = "";
    }
    selectedCase = menuContent.categories[parentId].data[id];
  };
</script>

<Menu
  {tabInfo}
  {menuContent}
  {selectedCase}
  {activeDocInTab}
  {changeCase}
  {nextTitle}
  {isLeftMenuOpen}
  {isRightMenuOpen}
  {setFalse}
  {toggleLeftMenu}
  {toggleRightMenu}
  {prevTitle} />
