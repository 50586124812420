<script>
  export let icon;
</script>

<style>
  .panther-card-wrapper {
    box-sizing: border-box;
    cursor: pointer;
  }
  .panther-icon {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  :global(.panther-icon > svg) {
    height: 64px;
    max-width: 200px;
    background-color: #f2f4f7;
  }
</style>

<div class="panther-card-wrapper">
  <div class="panther-icon">
    {@html icon}
  </div>
</div>
