<script>
  import AdvantagesCard from "./AdvantagesCard.svelte";
  import ContentWrapper from "components/ContentWrapper.svelte";
  import advantagesData from "../../../../advantages/advantagesInfo";
</script>

<style>
  .wrapper-cards {
    display: flex;
    justify-content: space-between;
    margin: 24px 0;
    flex-wrap: wrap;
  }
  .advantage-header {
    text-align: center;
    font-weight: 300;
    font-size: 28px;
    margin: 24px 0;
  }
  @media (max-width: 767px) {
    .wrapper-cards {
      margin: 24px 0;
      justify-content: center;
    }
  }
  @media (min-width: 768px) {
    .wrapper-cards {
      margin: 24px 0;
      justify-content: center;
    }
  }
  @media (min-width: 992px) {
    .wrapper-cards {
      margin: 24px 0;
      justify-content: space-between;
    }
  }
  @media (min-width: 1200px) {
    .wrapper-cards {
      margin: 24px 0;
    }
  }
  @media (min-width: 1400px) {
    .wrapper-cards {
      margin: 24px 0 0 0;
    }
  }
</style>

<div class="max-width">
  <ContentWrapper>
    <div class="advantage-header">Преимущества Астрал.Платформы</div>
    <div class="wrapper-cards">
      {#each advantagesData as { icon, text, title }, i}
        <AdvantagesCard {icon} {text} {title} />
      {/each}
    </div>
  </ContentWrapper>
</div>
