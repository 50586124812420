export const SWAGGER_TYPES = {
  Swagger: "SWAGGER",
  Rapidoc: "RAPIDOC"
}
const SWAGGER_LINKS = [
  ["#post-/files", "#operations—Файлы—files-post"],
  ["#get-/files/-file_uuid-", "#operations—Файлы—files-get"],
  ["#delete-/files/-file_uuid-", "#operations—Файлы—files-delete"],
  ["#patch-/files/-file_uuid-", "#operations—Файлы—files-move"],
  ["#get-/files/-file_uuid-.json", "#operations—Файлы—files-get-json"],
  ["#get-/events", "#operations—События—events-get"],
  ["#post-/events", "#operations—События—events-post"],
  ["#post-/events/confirm", "#operations—События—events-confirm"],
  ["#get-/events/-event_uuid-", "#operations—События—events-get-by-id"],
  ["#get-/events/unconfirmed", "#operations—События—events-unconfirmed"],
  [
    "#get-/events/-event_uuid--ext-",
    "#operations—События—event-archive-get"
  ]
];
export const RAPIDOC_TO_SWAGGER_MAP = new Map(SWAGGER_LINKS);
export const SWAGGER_TO_RAPIDOC_MAP = new Map(SWAGGER_LINKS.map(el => el.reverse()));