import { getMetricsStandParams } from "utils/metrics";
import { YA_COUNTER_PARAMS } from "constants/metrics";

class MetricsService {
  constructor() {
    this.ym = null;
    this.yaCounterId = null;
    this.standOptions = null;
  }

  init() {
    if (this.yaCounterId) return;
    this.standOptions = getMetricsStandParams();
    this.ym = window.ym;
    if (this.ym) {
      this.yaCounterId = this.standOptions;
      this.initMetrics();
    }
  }

  initMetrics() {
    this.ym && this.ym(this.yaCounterId, "init", { ...YA_COUNTER_PARAMS });
  }

  addUserParams(params) {
    this.ym && this.ym(this.yaCounterId, "userParams", params);
  }

  addParams(params) {
    this.ym && this.ym(this.yaCounterId, "params", params);
  }

  hit(url) {
    this.ym && this.ym(this.yaCounterId, "hit", url);
  }

  setUserID(userID) {
    this.ym && this.ym(this.yaCounterId, "setUserID", userID);
  }

  reachGoal(target, params) {
    this.ym && this.ym(this.yaCounterId, "reachGoal", target, params);
  }
}

export default new MetricsService();
