<script>
  import WhiteHeader from "components/Header/WhiteHeader.svelte";
  import CommonHeader from "components/Header/CommonHeader.svelte";
  import newsInfo from "../../news/newsInfo.json";
  export let isMain;
  export let getProps;

</script>

<style>

</style>

{#if isMain}
  <CommonHeader {getProps} {newsInfo} />
{:else}
  <WhiteHeader {getProps} />
{/if}
