<script>
  import { navigate } from "svelte-routing";
  import { activeService, activeDoc } from "../../../store";
  export let service;
  const { title, icon } = service;

  let goToSelectService = () => {
    if ($activeService !== service) {
      activeDoc.setActiveDoc({});
      sessionStorage.setItem("docPosition", JSON.stringify($activeDoc));
    }
    activeService.setActiveService(service);
    navigate(`/service/${service.link}`);
  };
</script>

<style>
  .list-item {
    width: 100%;
    display: flex;
    position: relative;
    box-sizing: border-box;
    text-align: left;
    align-items: center;
    padding: 12px 16px;
    justify-content: flex-start;
    text-decoration: none;
  }
  .list-item:hover {
    text-decoration: none;
    background-color: rgba(0, 86, 214, 0.05);
    cursor: pointer;
  }
  .service-list-icon {
    width: 50px;
    height: 50px;
    margin-right: 12px;
  }
  .service-list-text {
    font-size: 14px;
    font-weight: bold;
    color: #2e3e56;
  }
  :global(.list-item.active) {
    background-color: rgb(0, 86, 214);
  }
  :global(.list-item.active:hover) {
    background-color: rgb(0, 86, 214);
  }
  :global(.list-item.active .service-list-text) {
    color: #fff;
  }
  :global(.service-list-icon > svg) {
    width: 50px;
    height: 50px;
  }
  :global(.service-list-icon > svg path) {
    fill: #1666de;
  }
  :global(.list-item.active .service-list-icon > svg path) {
    fill: #fff;
  }
</style>

<div
  class="list-item {service === $activeService && 'active'}"
  on:click={goToSelectService}>
  <div class="service-list-icon">
    {@html icon}
  </div>
  <div class="service-list-text">{title}</div>
</div>
