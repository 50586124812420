<script>
  import Select from "svelte-select";
  import serviceInfo from "../../../../services/Info.json";
  import { selectedServices } from "../../../store";
  import { createEventDispatcher } from "svelte";
  const dispatch = createEventDispatcher();

  export let form;
  let allService = Object.values(serviceInfo)
    .filter(service => !service.isInternal)
    .map(service => service.title);

  function handleSelect(event) {
    const { detail } = event;
    const selected = detail ? detail.map(info => info.value) : [];
    selectedServices.setSelectService(detail ? detail : []);
    form.change("services", selected);
  }

  function handleClear() {
    selectedServices.setSelectService([]);
    form.change("services", []);
  }
</script>

<style>
  :global(#select_services .multiSelectItem) {
    margin: 4px;
  }
  :global(#select_services .selectContainer) {
    background: #ffffff;
    border: 1px solid #bec3c9;
    box-shadow: 0 0 0 0 transparent;
    border-radius: 6px;
    min-height: 50px;
    outline: none;
    transition: box-shadow 0.2s, border 0.2s;
    display: flex;
    align-items: center;
    margin: 0;
  }

  :global(#select_services .multiSelectItem_label) {
    font-size: 14px;
  }
  :global(#select_services .selectContainer.focused) {
    box-shadow: 0 0 0 1px #0056d6;
    border-color: #0056d6;
  }
  :global(#select_services input) {
    font-size: 16px;
  }
  :global(#select_services .selectContainer.error-field) {
    box-shadow: 0 0 0 1px red;
    border-color: red;
  }
  :global(#select_services .selectContainer.error-field input::placeholder) {
    color: red;
  }
</style>

<div class="field" id="select_services">
  <Select
    items={allService || []}
    value={$selectedServices && $selectedServices.length ? $selectedServices : null}
    on:select={handleSelect}
    inputAttributes={{ name: 'services' }}
    noOptionsMessage="Нет данных"
    placeholder="Интересующие сервисы"
    isMulti={true}
    {handleClear} />
  <span class="helper-error" id="services_error" />
</div>
