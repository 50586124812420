<script>
  export let text;
  export let icon;
  export let title;
</script>

<style>
  .advantages-card-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 36px 0;
    width: calc((100% - 70px) / 2);
  }
  .advantages-icon {
    width: 92px;
    height: 92px;
    background: white;
    border-radius: 50%;
  }
  .advantages-text {
    font-size: 18px;
    padding: 1rem 1rem 1rem 0;
  }
  .advantages-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 1rem 1rem 0;
  }
  .advantages-title {
    font-size: 18px;
    margin-left: 25px;
    font-weight: 600;
  }
  @media (max-width: 767px) {
    .advantages-card-wrapper {
      width: calc((100% - 70px) / 1);
      align-items: center;
      margin: 12px 0;
    }
    .advantages-header {
      display: flex;
      flex-direction: column;
      text-align: center;
    }
    .advantages-text {
      text-align: center;
    }
    .advantages-icon {
      margin-bottom: 16px;
    }
  }
  @media (min-width: 768px) {
    .advantages-card-wrapper {
      width: calc((100% - 70px) / 1);
      align-items: center;
      margin: 16px 0;
    }
    .advantages-header {
      display: flex;
      flex-direction: column;
      text-align: center;
    }
    .advantages-text {
      text-align: center;
    }
    .advantages-icon {
      margin-bottom: 16px;
    }
  }
  @media (min-width: 992px) {
    .advantages-card-wrapper {
      width: calc((100% - 70px) / 2);
      align-items: flex-start;
      margin: 24px 0;
    }
    .advantages-header {
      display: flex;
      flex-direction: row;
      text-align: left;
    }
    .advantages-text {
      text-align: left;
    }
    .advantages-icon {
      margin-bottom: 0;
    }
  }
  @media (min-width: 1200px) {
    .advantages-card-wrapper {
      width: calc((100% - 70px) / 2);
    }
    .advantages-card-wrapper:nth-child(2n + 1) {
      margin-right: 15px;
    }
  }
  @media (min-width: 1400px) {
    .advantages-card-wrapper {
      width: calc((100% - 70px) / 2);
    }
    .advantages-card-wrapper:nth-child(2n + 1) {
      margin-right: 15px;
    }
  }
</style>

<div class="advantages-card-wrapper">
  <div class="advantages-header">
    <div class="advantages-icon">
      {@html icon}
    </div>
    <div class="advantages-title">{title}</div>
  </div>
  <div class="advantages-text">
    {@html text}
  </div>
</div>
