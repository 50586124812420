<script>
  import { onDestroy, onMount } from "svelte";
  import Integration from "components/Integration.svelte";
  import Header from "components/Header.svelte";
  import Main from "components/Pages/Main.svelte";
  import BackToTopButton from "components/Common/Buttons/BackToTopButton.svelte";
  import About from "components/Pages/About.svelte";
  import Contacts from "components/Pages/Contacts.svelte";
  import News from "components/Pages/News.svelte";
  import NewsPost from "components/Pages/NewsPost.svelte";
  import Documentation from "components/Pages/Documentation.svelte";
  import Content from "components/Pages/Content.svelte";
  import Page404 from "components/Pages/Page404.svelte";
  import Footer from "components/Footer.svelte";
  import LK from "components/Pages/LK.svelte";
  import { Router, Route } from "svelte-routing";
  import MetricsService from "./services/MetricsService";
  import {
    trackScroll,
    backToTop,
    blockHorizontalScroll
  } from "utils/scrollUtils";
  import serviceInfo from "../services/Info.json";
  import form from "components/Integration/createForm";
  import Metrics from "./components/Common/Metrics.svelte";

  let goTopBtn;

  let isMain = window.location.pathname === "/";

  let getProps = ({ location, href, isPartiallyCurrent, isCurrent }) => {
    const { submitting, hasValidationErrors } = form.getState();
    if (!submitting && hasValidationErrors)
      form.getRegisteredFields().map(field => form.resetFieldState(field));
    isMain = location.pathname === "/";
    const isActive = href === "/" ? isCurrent : isPartiallyCurrent || isCurrent;
    if (isActive) {
      return { class: "active" };
    }
    return {};
  };

  onMount(() => {
    MetricsService.init();
    goTopBtn = document.querySelector(".back_to_top");
    window.addEventListener("scroll", trackScroll(goTopBtn));
    goTopBtn.addEventListener("click", backToTop);
    window.addEventListener("scroll", blockHorizontalScroll, false);
    window.addEventListener(
      "load",
      function() {
        let oldHref = document.location.href;
        const bodyDOM = document.querySelector("body");
        const observer = new MutationObserver(function() {
          const newHref = document.location.href;
          if (oldHref !== newHref) {
            oldHref = newHref;
            MetricsService.hit(newHref);
          }
        });
        const config = {
          childList: true,
          subtree: true
        };
        observer.observe(bodyDOM, config);
      },
      true
    );
  });

  onDestroy(() => {
    window.removeEventListener("scroll", blockHorizontalScroll, false);
    window.removeEventListener("scroll", trackScroll(goTopBtn), false);
  });

  let rootLink = Object.keys(serviceInfo);
</script>

<style>
  .wrapper {
    display: flex;
    flex-direction: column;
    min-height: 100%;
  }
  .content {
    flex: 1 0 auto;
  }
</style>

<Router>
  <div class="wrapper">
    <Header {getProps} {isMain} />
    <Route path="lk" component={LK} />
    <div class="content">
      <Route path="about" component={About} />
      <Route path="contact" component={Contacts} />
      <Route path="news" component={News} />
      <Route path="news/:id" component={NewsPost} />
      <Route path="documentation" component={Documentation} />
      {#each rootLink as element}
        <Route path={`service/${element}`}>
          <Content type={element} />
        </Route>
      {/each}
      <Route path="/">
        <Main />
      </Route>
      <Route component={Page404} />
      <Integration {isMain} {form} />
    </div>
    <BackToTopButton />
    <Footer />
    <Metrics />
  </div>
</Router>
