<script>
  import { onMount } from "svelte";
  import ContentWrapper from "components/ContentWrapper.svelte";
  import ContactCard from "../Contact/ContactCard.svelte";
  import InfoCard from "../Contact/InfoCard.svelte";
  import contactData from "../../../contacts/contactsInfo";
  import { CONTACT_TYPES } from "constants/contacts";
  window.scrollTo(0, 0);
  const cooperationContact = contactData.filter(
    el => el.сontactType === CONTACT_TYPES.cooperation
  );
  const techAssistanceContact = contactData.filter(
    el => el.сontactType === CONTACT_TYPES.techAssistance
  );
  let newCooperationContact = [],
    newTechAssistanceContact = [],
    coopCntCount,
    techAssisCntCount;
  //TODO в утилу
  const isPrime = number => {
    if (number <= 1) return false;

    // The check for the number 2 and 3
    if (number <= 3) return false;

    if (number % 2 === 0 || number % 3 === 0) return false;

    for (var i = 5; i * i <= number; i = i + 6) {
      if (number % i === 0 || number % (i + 2) === 0) return false;
    }

    return true;
  };

  const getValueContactCount = array => {
    let contactCount,
      newArray = [];
    let width = document.documentElement.clientWidth;
    let size = parseInt(array.length);
    if (isPrime(size)) {
      size++;
    }
    if (width < 765) {
      contactCount = 1;
    } else if (width < 992) {
      if (size % 3 === 0) {
        contactCount = 3;
      } else if (size % 2 === 0) {
        contactCount = 2;
      } else {
        contactCount = 1;
      }
    } else if (width < 1200) {
      if (size % 3 === 0) {
        contactCount = 3;
      } else if (size % 2 === 0) {
        contactCount = 2;
      } else {
        contactCount = 1;
      }
    } else if (width >= 1200) {
      if (size % 4 === 0) {
        contactCount = 4;
      } else if (size % 3 === 0) {
        contactCount = 3;
      } else if (size % 2 === 0) {
        contactCount = 2;
      } else {
        contactCount = 1;
      }
    }
    for (let i = 0; i < array.length; i += contactCount) {
      newArray.push(array.slice(i, i + contactCount));
    }
    return newArray;
  };
  newCooperationContact = getValueContactCount(cooperationContact);
  newTechAssistanceContact = getValueContactCount(techAssistanceContact);
  coopCntCount = newCooperationContact[0].length;
  techAssisCntCount = newTechAssistanceContact[0].length;
  onMount(() => {
    window.addEventListener("resize", () => {
      newCooperationContact = getValueContactCount(cooperationContact);
      newTechAssistanceContact = getValueContactCount(techAssistanceContact);
      coopCntCount = newCooperationContact[0].length;
      techAssisCntCount = newTechAssistanceContact[0].length;
    });
  });
</script>

<style>
  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin: 24px 0 12px;
  }
  .contact-title {
    font-size: 40px;
    font-weight: 500;
    margin-bottom: 12px;
    color: #000;
  }
  .contact-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
  }
  .left-contact {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    width: 100%;
    margin-bottom: 36px;
  }
  .contact-header {
    font-size: 14px;
    opacity: 0.5;
    margin-bottom: 24px;
  }
  .right-contact {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 36px 0;
  }
  .card-wrapper {
    width: 100%;
    margin: auto;
  }
  .card-row {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    margin: auto;
  }
  .card-row:not(:last-child) {
    margin-bottom: 48px;
  }
  :global(.card-row > div) {
    flex: 0 0 calc(100% / var(--coopCntCount));
  }
  .tech-assistance {
    display: flex;
    justify-content: space-around;
    width: 100%;
    margin: auto;
  }
  .tech-assistance:not(:last-child) {
    margin-bottom: 48px;
  }
  :global(.tech-assistance > div) {
    flex: 0 0 calc(100% / var(--techAssisCntCount));
  }
  .tech-assistance.to-start-flex-block {
    justify-content: flex-start;
  }
</style>

<div class="max-width">
  <ContentWrapper>
    <div class="container">
      <div class="contact-title">Контакты</div>
      <div class="contact-container w-100">
        <div class="left-contact">
<!--          <div class="contact-header">{CONTACT_TYPES.cooperation}:</div>-->
          <div class="card-wrapper">
            {#each newCooperationContact as cooperationArray, i}
              <div class="card-row" style="--coopCntCount: {coopCntCount}">
                {#each cooperationArray as { fio, email, phone, tel, image }, i}
                  <ContactCard {fio} {email} {phone} {tel} {image} />
                {/each}
              </div>
            {/each}
          </div>
        </div>
        <div class="right-contact">
          {#if newTechAssistanceContact[0].length > 1}
            <div class="contact-header">{CONTACT_TYPES.techAssistance}:</div>
          {/if}
          <div class="card-wrapper">
            {#each newTechAssistanceContact as techAssistanceArray, i}
              <div
                class="tech-assistance {newTechAssistanceContact[0].length > 1 && 'to-start-flex-block'}"
                style="--techAssisCntCount: {techAssisCntCount}">
                {#each techAssistanceArray as { fio, email, phone, tel, image }, i}
                  <InfoCard {fio} {email} {phone} {tel} {image} />
                {/each}
              </div>
            {/each}
          </div>
        </div>
      </div>
    </div>
  </ContentWrapper>
</div>
