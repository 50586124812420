<script>
  import PantherCard from "./PantherCard.svelte";
  import MultiCarousel from "components/Common/MultiCarousel.svelte";
  import ContentWrapper from "components/ContentWrapper.svelte";
  import partnersImages from "../../../../partners/partnersIcon";
  import ArrowLeft from "static/ArrowIcon/ArrowLeft.svelte";
  import ArrowRight from "static/ArrowIcon/ArrowRight.svelte";
</script>

<style>
  .panther-wrapper {
    background-color: #f2f4f7;
    margin: 50px 0;
  }
  .wrapper-cards {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 60px;
  }
  .panthers-header {
    text-align: center;
    font-weight: 300;
    font-size: 28px;
    padding-top: 20px;
  }
  .arrow-icon {
    cursor: pointer;
  }
  #MyCarousel {
    width: 100%;
  }
  :global(.multicarousel) {
    position: relative;
    overflow: hidden;
    white-space: nowrap;
  }
  :global(.multicarousel .items) {
    position: relative;
    white-space: nowrap;
  }
  :global(.multicarousel .items > div) {
    display: none;
  }
  :global(.multicarousel .items > div.active) {
    display: inline-block;
    border: none;
    opacity: 1;
  }
</style>

<div class="panther-wrapper w-100">
  <div class="panthers-header">Партнеры</div>
  <div class="max-width">
    <ContentWrapper>
      <div class="wrapper-cards">
        <MultiCarousel>
          <span class="control" slot="left-control">
            <ArrowLeft />
          </span>
          {#each partnersImages as icon, i}
            <PantherCard {icon} />
          {/each}
          <span class="control" slot="right-control">
            <ArrowRight />
          </span>
        </MultiCarousel>
      </div>
    </ContentWrapper>
  </div>
</div>
