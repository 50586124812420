<script>
  import Siema from "siema";
  import { onMount } from "svelte";
  export let perPage = 3;
  export let loop = true;
  export let autoplay = 5000;
  let siema;
  let controller;
  let timer;
  let panthersCount = perPage;
  let isClicked = false;
  const getPanthersCount = () => {
    const width = document.documentElement.clientWidth;
    if (width < 768) panthersCount = 1;
    else if (width < 992) panthersCount = 2;
    else if (width < 1200) panthersCount = 3;
    else if (width < 1400) panthersCount = 4;
    else panthersCount = 5;
  };
  getPanthersCount();

  const changePanthersInCarousel = () => {
    getPanthersCount();
    if (perPage !== panthersCount) {
      let currentSlide = controller.currentSlide;
      controller.destroy(true, () => {
        controller = new Siema({
          selector: siema,
          duration: 600,
          easing: "ease-out",
          perPage: panthersCount,
          loop: true,
          startIndex: currentSlide
        });
      });
      perPage = panthersCount;
    }
  };

  onMount(() => {
    controller = new Siema({
      selector: siema,
      duration: 600,
      easing: "ease-out",
      perPage: panthersCount,
      loop: true
    });
    autoplay && setInterval(right, autoplay);
    window.addEventListener("resize", changePanthersInCarousel);
    return () => {
      autoplay && clearTimeout(timer);
      controller.destroy();
    };
  });
  function left() {
    if (!document.hidden && !isClicked) {
      isClicked = true;
      controller.prev();
      setTimeout(() => {
        isClicked = false;
      }, 800);
    }
  }
  function right() {
    if (!document.hidden && !isClicked) {
      isClicked = true;
      controller.next();
      setTimeout(() => {
        isClicked = false;
      }, 800);
    }
  }
  function go(index) {
    if (!document.hidden) {
      controller.goTo(index);
    }
  }
</script>

<style>
  .carousel {
    position: relative;
    width: 100%;
    justify-content: center;
    align-items: center;
  }

  button {
    position: absolute;
    width: 40px;
    height: 40px;
    top: 50%;
    z-index: 50;
    margin-top: -20px;
    border: none;
    background-color: transparent;
    transition: all 0.7ms;
  }
  button:hover {
    cursor: pointer;
    background-color: rgba(0, 86, 214, 0.06);
    border-radius: 50%;
  }
  button:focus {
    outline: none;
  }
  button:active {
    transform: scale(0.97);
  }
  .left {
    left: -45px;
    transition: all 0.7ms;
  }
  .right {
    right: -45px;
    transition: all 0.7ms;
  }
</style>

<div class="carousel">
  <button class="left" on:click={left}>
    <slot name="left-control" />
  </button>
  <div class="slides" bind:this={siema}>
    <slot />
  </div>
  <button class="right" on:click={right}>
    <slot name="right-control" />
  </button>
</div>
