import { validateEmail, validatePhone } from "utils/validateUtils";

const validation = values => {
  const errors = {};
  if (!values.FIO) {
    errors.FIO = "Обязательно к заполнению";
  }
  if (!values.organization) {
    errors.organization = "Обязательно к заполнению";
  }
  if (!!values.email && validateEmail(values.email)) {
    errors.email = "Неправильный формат Email";
  }
  if (!values.email && !values.phone_mobile) {
    errors.email = "Обязательно к заполнению";
  }
  if (!values.services || !values.services.length) {
    errors.services = "Обязательно к заполнению";
  }
  if (!!values.phone_mobile && validatePhone(values.phone_mobile)) {
    errors.phone_mobile = "Неправильный формат номера телефона";
  }
  if (!values.phone_mobile && !values.email) {
    errors.phone_mobile = "Обязательно к заполнению";
  }
  return errors;
};
export default validation;
