<script>
  import TextWrapper from "components/TextWrapper.svelte";
  export let prevNews;
  export let nextNews;
  export let goToPrevNews;
  export let goToNextNews;
</script>

<style>
  .navigation-news {
    position: relative;
    margin: 48px 0;
  }
  .navigation-news::before {
    content: " ";
    left: 50%;
    border-right: 1px solid #e2e3e7;
    display: block;
    position: absolute;
    top: 20px;
    bottom: 20px;
  }
  .container {
    margin-right: auto;
    margin-left: auto;
  }
  .row {
    margin-right: -15px;
    margin-left: -15px;
    display: flex;
  }
  .prev-news-container {
    width: 50%;
  }
  .prev-news {
    display: flex;
    width: 100%;
    -webkit-box-align: center;
    align-items: center;
    cursor: pointer;
    padding: 12px;
  }
  .prev-news:hover .news-header {
    color: #175eea;
  }
  next-news-container {
    width: 50%;
  }
  .next-news {
    display: flex;
    width: 100%;
    -webkit-box-align: center;
    align-items: center;
    cursor: pointer;
    padding: 12px;
  }
  .next-news:hover .news-header {
    color: #175eea;
  }
  .title {
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: 700 !important;
    font-size: 12px;
    color: #000;
    margin-bottom: 12px;
  }
  .news-header {
    padding-left: 0;
    margin-left: 0;
    font-size: 18px;
  }
  .prev-news-info {
    text-align: left;
    margin-right: 0;
    -webkit-box-flex: 1;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0 30px 0 0;
  }
  .next-news-info {
    text-align: right;
    margin-right: 0;
    -webkit-box-flex: 1;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 0 0 0 30px;
  }
  .image-wrapper {
    width: 150px;
    height: 70px;
  }
  .image {
    width: 100%;
    height: 100%;
    display: flex;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    border-radius: 15px;
  }
  .left-image {
    margin-left: -15px;
    margin-right: 30px;
  }
  .right-image {
    margin-left: 30px;
    margin-right: -15px;
  }
  .button {
    -webkit-appearance: none;
    background: transparent;
    border: 0;
    outline: 0;
  }
  .button.left {
    margin-right: 24px;
  }
  .button.right {
    margin-left: 24px;
  }
  svg {
    width: 28px;
    height: 44px;
    padding: 4px;
  }

  .arrow {
    cursor: pointer;
  }

  .left {
    left: 42%;
  }

  .right {
    left: 58%;
  }

  .next-news:hover polyline {
    stroke-width: 4;
  }
  .prev-news:hover polyline {
    stroke-width: 4;
  }
  .left:hover polyline,
  .left:focus polyline {
    stroke-width: 4;
  }

  .left:active polyline {
    stroke-width: 4;
    transition: all 100ms ease-in-out;
  }

  .right:hover polyline,
  .right:focus polyline {
    stroke-width: 4;
  }

  .right:active polyline {
    stroke-width: 4;
    transition: all 100ms ease-in-out;
  }

  polyline {
    transition: all 250ms ease-in-out;
  }
  @media (max-width: 767px) {
    .navigation-news {
      margin: 24px 0;
    }
    .row {
      flex-direction: column;
    }
    .navigation-news::before {
      border-right: none;
    }
    .prev-news-container {
      width: 100%;
    }
    .prev-news {
      margin-bottom: 16px;
    }
    .next-news-container {
      width: 100%;
    }
    .prev-news-info {
      text-align: left;
      padding: 0;
      align-items: flex-start;
    }
    .next-news-info {
      text-align: left;
      padding: 0;
      align-items: flex-start;
    }
  }
  @media (min-width: 768px) {
    .navigation-news {
      margin: 24px 0;
    }
    .row {
      flex-direction: column;
    }
    .navigation-news::before {
      border-right: none;
    }
    .prev-news-container {
      width: 100%;
    }
    .prev-news {
      margin-bottom: 16px;
    }
    .next-news-container {
      width: 100%;
    }
    .prev-news-info {
      text-align: left;
      padding: 0;
      align-items: flex-start;
    }
    .next-news-info {
      text-align: left;
      padding: 0;
      align-items: flex-start;
    }
  }
  @media (min-width: 992px) {
    .navigation-news {
      margin: 48px 0;
    }
    .row {
      flex-direction: row;
    }
    .navigation-news::before {
      border-right: 1px solid #e2e3e7;
    }
    .prev-news-container {
      width: 50%;
    }
    .prev-news {
      margin-bottom: 0;
    }
    .next-news-container {
      width: 50%;
    }
    .prev-news-info {
      text-align: left;
      padding: 0;
      align-items: flex-start;
    }
    .next-news-info {
      text-align: right;
      padding: 0;
      align-items: flex-end;
    }
  }
</style>

<TextWrapper>
  <div class="navigation-news">
    <div class="container">
      <div class="row">
        <div class="prev-news-container">
          {#if prevNews}
            <div class="prev-news" on:click={goToPrevNews}>
              {#if !!prevNews.image}
                <div class="image-wrapper">
                  <div
                    class="image left-image"
                    style="background-image: url({prevNews.image})" />
                </div>
              {:else}
                <div class="button arrow left">
                  <svg
                    width="60px"
                    height="80px"
                    viewBox="0 0 50 80"
                    xml:space="preserve">
                    <polyline
                      fill="none"
                      stroke="#175eea"
                      stroke-width="3"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      points=" 45.63,75.8 0.375,38.087 45.63,0.375 " />
                  </svg>
                </div>
              {/if}
              <div class="prev-news-info">
                <div class="title">Предыдущая</div>
                <div class="news-header">{prevNews.title}</div>
              </div>
            </div>
          {/if}
        </div>

        <div class="next-news-container">
          {#if nextNews}
            <div class="next-news" on:click={goToNextNews}>
              <div class="next-news-info">
                <div class="title">Следующая</div>
                <div class="news-header">{nextNews.title}</div>
              </div>
              {#if !!nextNews.image}
                <div class="image-wrapper">
                  <div
                    class="image right-image"
                    style="background-image: url({nextNews.image})" />
                </div>
              {:else}
                <div class="button arrow right">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    width="60px"
                    height="80px"
                    viewBox="0 0 50 80"
                    xml:space="preserve">
                    <polyline
                      fill="none"
                      stroke="#175eea"
                      stroke-width="3"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      points=" 0.375,0.375 45.63,38.087 0.375,75.8 " />
                  </svg>
                </div>
              {/if}
            </div>
          {/if}
        </div>
      </div>
    </div>
  </div>
</TextWrapper>
