<script>
  import MailIcon from "static/MailClose.svelte";
  import PhoneIcon from "static/Phone.svelte";
  export let fio;
  export let email;
  export let phone;
  export let tel;
  export let image;
</script>

<style>
  .contact-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
  }
  .contact-card:not(:first-child) {
    box-shadow: -1px 0px 0px 0px #dfdfdf;
  }
  .contact-name {
    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: bold;
  }
  .contact-name a {
    color: #2e3e56;
  }
  .contact-email {
    display: flex;
    align-items: center;
    font-size: 18px;
  }
  .contact-phone {
    display: flex;
    align-items: center;
    font-size: 14px;
  }
  .contact-card > div:not(:last-child) {
    margin-bottom: 24px;
  }
  .contact-icon {
    width: 24px;
    height: 24px;
    margin-right: 12px;
  }
  :global(.contact-icon > svg > path) {
    fill: #0d5aa7;
  }
  .user-wrapper {
    display: flex;
    align-items: center;
  }
  .image-container {
    display: flex;
    color: #fff;
    align-items: center;
    justify-content: center;
    background-color: rgba(82, 92, 105, 0.23);
    border-radius: 50%;
    overflow: hidden;
    width: 120px;
    height: 120px;
  }
  img {
    width: 100%;
    height: 100%;
  }
  @media (max-width: 768px) {
    .image-container {
      width: 120px;
      height: 120px;
    }
    .contact-name {
      font-size: 18px;
    }
    .contact-email {
      font-size: 16px;
    }
    .contact-phone {
      font-size: 14px;
    }
    .contact-icon {
      width: 24px;
      height: 24px;
      margin-right: 12px;
    }
  }
  @media (min-width: 768px) {
    .image-container {
      width: 100px;
      height: 100px;
    }
    .contact-name {
      font-size: 16px;
    }
    .contact-email {
      font-size: 14px;
    }
    .contact-phone {
      font-size: 12px;
    }
    .contact-icon {
      width: 20px;
      height: 20px;
      margin-right: 8px;
    }
  }
  @media (min-width: 992px) {
    .image-container {
      width: 120px;
      height: 120px;
    }
    .contact-name {
      font-size: 18px;
    }
    .contact-email {
      font-size: 16px;
    }
    .contact-phone {
      font-size: 14px;
    }
    .contact-icon {
      width: 24px;
      height: 24px;
      margin-right: 12px;
    }
  }
</style>

<div class="contact-card">
  <div class="image-container">
    <img src={image} />
  </div>
  {#if fio}
    <div class="user-wrapper">
      <div class="contact-name">{fio}</div>
    </div>
  {/if}
  {#if email}
    <div class="contact-email">
      <div class="contact-icon">
        <MailIcon />
      </div>
      <a href="mailto:{email}">{email}</a>
    </div>
  {/if}
  {#if phone && tel}
    <div class="contact-phone">
      <div class="contact-icon">
        <PhoneIcon />
      </div>
      <a href="tel:{tel}">{phone}</a>
    </div>
  {/if}
</div>
