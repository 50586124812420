export const validateEmail = email => {
  const reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
  if (reg.test(email) === false) {
    return true;
  }
};

export const validatePhone = phone => {
  const reg = /^\+[\d]{1}\([\d]{2,3}\)[\d]{2,3}-[\d]{2,3}-[\d]{2,3}$/;
  if (reg.test(phone) === false) {
    return true;
  }
};

export const correctInn = value => {
  if (!value) return false;

  const inn = String(value);

  if (inn.length === 10) {
    const checkSum = (
      ((2 * inn[0] +
        4 * inn[1] +
        10 * inn[2] +
        3 * inn[3] +
        5 * inn[4] +
        9 * inn[5] +
        4 * inn[6] +
        6 * inn[7] +
        8 * inn[8]) %
        11) %
      10
    ).toString();

    if (inn[9] === checkSum) {
      return false;
    }
  }
  if (inn.length === 12) {
    const checkSum1 = (
      ((7 * inn[0] +
        2 * inn[1] +
        4 * inn[2] +
        10 * inn[3] +
        3 * inn[4] +
        5 * inn[5] +
        9 * inn[6] +
        4 * inn[7] +
        6 * inn[8] +
        8 * inn[9]) %
        11) %
      10
    ).toString();

    const checkSum2 = (
      ((3 * inn[0] +
        7 * inn[1] +
        2 * inn[2] +
        4 * inn[3] +
        10 * inn[4] +
        3 * inn[5] +
        5 * inn[6] +
        9 * inn[7] +
        4 * inn[8] +
        6 * inn[9] +
        8 * inn[10]) %
        11) %
      10
    ).toString();

    if (inn[10] === checkSum1 && inn[11] === checkSum2) {
      return false;
    }
  }
  return true;
};

export const correctKpp = value => {
  const KPP_REGEXP = /^([0-9]{1}[1-9]{1}|[1-9]{1}[0-9]{1})([0-9]{2})([0-9A-Z]{2})([0-9]{3})$/;
  return !(!value || KPP_REGEXP.test(value));
};
