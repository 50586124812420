<script>
  import { onMount, tick } from "svelte";
  import ContentWrapper from "./ContentWrapper.svelte";
  import { replaceSwaggerHash } from "utils/urlUtils";
  import { activeTabInDocumentation } from "../store";
  import {
    RAPIDOC_TO_SWAGGER_MAP,
    SWAGGER_TO_RAPIDOC_MAP,
    SWAGGER_TYPES
  } from "constants/swagger";

  let swaggerType;
  let domIsLoaded = false;
  let ui;
  let observer;

  window.addEventListener("load", function(event) {
    domIsLoaded = true;
    replaceHash();
    openAndScrollSwagger();
    scrollRapidoc();
  });

  function stopMutation(observer) {
    observer.disconnect();
  }
  function getMutationRapidocTree() {
    const target = document.getElementById("rapidoc").shadowRoot;
    const config = {
      childList: true,
      subtree: true
    };

    // Колбэк-функция при срабатывании мутации
    const callback = function(mutationsList, observer) {
      let requiredsFields = ["file_uuid", "event_uuid", "ext"];
      for (let mutation of mutationsList) {
        if (mutation.type === "childList") {
          const newItems = Array.from(mutation.addedNodes);
          if (!!newItems.length) {
            const [filteredNewItem] = newItems.filter(
              node =>
                node.hasChildNodes() && !!node.querySelector("api-request")
            );
            const asyncFunc = async filteredNewItem => {
              const requestItem = filteredNewItem.querySelector("api-request");
              await tick();
              const inputs = Array.from(
                requestItem.shadowRoot.querySelectorAll("input")
              ).filter(
                input =>
                  input.type === "text" &&
                  requiredsFields.includes(input.dataset.pname)
              );
              inputs.map(input => {
                if (input.dataset.pname === "ext") {
                  input.addEventListener("input", function(event) {
                    if (input.value !== ".tar.gz" && input.value !== ".zip")
                      input.style.borderColor = "red";
                    else input.style.borderColor = "initial";
                  });
                  input.addEventListener("blur", function(event) {
                    if (input.value !== ".tar.gz" && input.value !== ".zip")
                      input.style.borderColor = "red";
                    else input.style.borderColor = "initial";
                  });
                } else {
                  input.value = " ";
                  input.addEventListener("input", function(event) {
                    if (input.selectionStart < 1) input.value = " ";
                    if (input.value === " ") input.style.borderColor = "red";
                    else input.style.borderColor = "initial";
                  });
                  input.addEventListener("blur", function(event) {
                    if (input.value === " ") input.style.borderColor = "red";
                    else input.style.borderColor = "initial";
                  });
                  input.addEventListener("copy", function(event) {
                    if (input.selectionStart < 1) input.selectionStart = 1;
                  });
                  input.addEventListener("cut", function(event) {
                    if (input.selectionStart < 1) input.selectionStart = 1;
                  });
                  input.addEventListener("paste", function(event) {
                    if (input.selectionStart < 1) input.selectionStart = 1;
                  });
                  input.addEventListener("select", function(event) {
                    if (input.selectionStart < 1) input.selectionStart = 1;
                  });
                  if (input.value === "") input.value = " ";
                }
              });
            };
            if (filteredNewItem) {
              asyncFunc(filteredNewItem);
            }
          }
        }
      }
    };

    // Создаём экземпляр наблюдателя с указанной функцией колбэка
    const observer = new MutationObserver(callback);
    // Начинаем наблюдение за настроенными изменениями целевого элемента
    observer.observe(target, config);
    return observer;
  }

  onMount(() => {
    try {
      ui = SwaggerUIBundle({
        url: "./openapi.json",
        dom_id: "#swagger-ui",
        deepLinking: true,
        presets: [
          SwaggerUIBundle.presets.apis,
          SwaggerUIStandalonePreset.slice(1)
        ],
        layout: "StandaloneLayout"
      });
      const rapidocEl = document.getElementById("rapidoc");
      rapidocEl.addEventListener("after-try", e => {
        let area = e.path[0].shadowRoot;
        let requiredsFields = ["file_uuid", "event_uuid", "ext"];
        let inputs = Array.from(area.querySelectorAll("input")).filter(input =>
          requiredsFields.includes(input.dataset.pname)
        );
        inputs.forEach(input => {
          if (
            input.dataset.pname === "ext" &&
            (input.value !== ".tar.gz" && input.value !== ".zip")
          )
            input.style.borderColor = "red";
          else if (input.value === " ") input.style.borderColor = "red";
        });
      });
      if (!localStorage.getItem("swaggerType")) {
        swaggerType = SWAGGER_TYPES.Swagger;
        localStorage.setItem("swaggerType", swaggerType);
      } else swaggerType = localStorage.getItem("swaggerType");
      rapidocEl.addEventListener("spec-loaded", e => {
        if (swaggerType === SWAGGER_TYPES.Rapidoc) {
          observer = getMutationRapidocTree();
        }
      });
      openAndScrollSwagger();
      window.ui = ui;
    } catch (e) {
      console.error(e);
    }
    () => (domIsLoaded = false);
  });

  function handleChangeSwagger(type) {
    localStorage.setItem("swaggerType", type);
    swaggerType = type;
    if (swaggerType === SWAGGER_TYPES.Rapidoc)
      observer = getMutationRapidocTree();
    else stopMutation(observer);
  }

  function scrollSwagger() {
    if ($activeTabInDocumentation === 3 && domIsLoaded) {
      setTimeout(() => {
        let target = document.getElementById(
          decodeURIComponent(location.hash).replace("#", "")
        );
        target &&
          window.scrollTo({
            top: target.offsetTop + 250,
            left: 0,
            behavior: "smooth"
          });
      }, 0);
    }
  }

  function scrollRapidoc() {
    if ($activeTabInDocumentation === 3 && domIsLoaded) {
      const rapidocEl = document.getElementById("rapidoc");
      const link = decodeURIComponent(location.hash).replace("#", "");
      rapidocEl.addEventListener("scroll", e => {
        e.preventDefault();
      });
      rapidocEl.scrollTo(link);
    }
  }

  function openSwagger() {
    const swaggerHash = replaceSwaggerHash(location);
    ui.layoutActions.show(swaggerHash, true);
  }
  function openAndScrollSwagger() {
    if (swaggerType === SWAGGER_TYPES.Swagger && ui) {
      openSwagger();
      scrollSwagger();
    }
  }

  function replaceHash() {
    const decodeHash = decodeURIComponent(location.hash);
    const hashMap =
      swaggerType === SWAGGER_TYPES.Swagger
        ? RAPIDOC_TO_SWAGGER_MAP
        : SWAGGER_TO_RAPIDOC_MAP;
    const hash = hashMap.get(decodeHash);
    history.replaceState("", "", hash);
  }

  $: replaceHash(swaggerType);
  $: openAndScrollSwagger(swaggerType, $activeTabInDocumentation);
  $: scrollRapidoc(swaggerType, $activeTabInDocumentation);
</script>

<style>
  #swagger-ui {
    width: 100%;
    height: 0;
    opacity: 0;
    visibility: hidden;
  }
  #swagger-ui.active {
    height: 100%;
    opacity: 1;
    visibility: visible;
  }

  #rapidoc {
    width: 100%;
    height: 0;
    opacity: 0;
    visibility: hidden;
  }
  #rapidoc.active {
    width: 100%;
    height: 100%;
    opacity: 1;
    visibility: visible;
  }

  .swagger-header {
    padding: 28px 24px 0;
    font-weight: bold;
    font-size: 18px;
    display: flex;
    align-content: center;
    justify-content: space-between;
  }
  .swagger-header:not(:last-child) {
    margin-right: 8px;
  }
  @media (max-width: 767px) {
    .swagger-header {
      flex-direction: column;
    }
  }
  .toggle_button_group {
    display: inline-flex;
    border-radius: 4px;
    margin: 12px 0;
  }

  .toggle_button_group .toggle_button {
    padding: 12px 16px;
    margin: 0;
    font-weight: 600;
    border-radius: 4px;
    text-transform: uppercase;
    border: 1px solid rgba(0, 0, 0, 0.12);
    cursor: pointer;
    user-select: none;
    vertical-align: middle;
    appearance: none;
    text-decoration: none;
    outline: 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: relative;
    box-sizing: border-box;
    background-color: #fff;
    font-family: inherit;
    font-size: 13px;
    letter-spacing: 1px;
  }
  .toggle_button_group .toggle_button:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .toggle_button_group .toggle_button.selected {
    background-color: rgb(0, 136, 204);
    color: #ffffff;
  }
</style>

<div>
  <div class="max-width">
    <ContentWrapper>
      <div class="swagger-header">
        <div>
          <a href="/openapi.json">Открыть схему API</a>
          <span>(в формате OpenAPI)</span>
        </div>
        <div class="toggle_button_group">
          <button
            class="toggle_button"
            class:selected={swaggerType === SWAGGER_TYPES.Swagger}
            on:click={() => handleChangeSwagger(SWAGGER_TYPES.Swagger)}>
            Swagger
          </button>
          <button
            class="toggle_button"
            class:selected={swaggerType === SWAGGER_TYPES.Rapidoc}
            on:click={() => handleChangeSwagger(SWAGGER_TYPES.Rapidoc)}>
            Rapidoc
          </button>
        </div>
      </div>
      <div
        id="swagger-ui"
        class:active={swaggerType === SWAGGER_TYPES.Swagger} />
      <rapi-doc
        id="rapidoc"
        class:active={swaggerType === SWAGGER_TYPES.Rapidoc}
        spec-url="./openapi.json"
        theme="light"
        primary-color="#0056d6"
        regular-font="Manrope, sans-serif"
        show-info={false}
        show-header={false}
        allow-server-selection={false}
        render-style="view" />
    </ContentWrapper>
  </div>
</div>
