<script>
  import { onMount } from "svelte";
  import { navigate } from "svelte-routing";
  import TextWrapper from "components/TextWrapper.svelte";
  import Download from "../NewsPost/Download.svelte";

  import Clip from "static/Clip.svelte";
  import { activeNews } from "../../store";
  import Divider from "components/Common/Divider.svelte";
  import ArrowBack from "static/ArrowToBack.svelte";
  import newsInfo from "../../../news/newsInfo.json";
  import NewsPostFooter from "../NewsPost/NewsPostFooter.svelte";
  import { getFileName } from "utils/index";
  import { createLink } from "utils/urlUtils";

  let news, prevNews, nextNews;
  const updateNews = newNews => {
    if (!Object.keys(newNews).length) {
      news = newsInfo.find(
        el => el.id === decodeURIComponent(location.pathname.split("/").pop())
      );
      activeNews.setActiveNews(news);
    } else {
      news = newNews;
    }
    const newsIndex = newsInfo.findIndex(
      el => el.id === decodeURIComponent(location.pathname.split("/").pop())
    );
    prevNews = newsInfo[newsIndex - 1];
    nextNews = newsInfo[newsIndex + 1];
  };

  updateNews($activeNews);

  window.scrollTo(0, 0);

  const goToAllNews = () => {
    navigate("/news");
  };
  const goToPrevNews = () => {
    navigate(`/news/${prevNews.id}`);
    updateNews(prevNews);
    window.scrollTo(0, 0);
  };
  const goToNextNews = () => {
    navigate(`/news/${nextNews.id}`);
    updateNews(nextNews);
    window.scrollTo(0, 0);
  };
</script>

<style>
  .news-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 65px 0;
    width: 100%;
  }
  .news-date {
    font-size: 16px;
    opacity: 0.5;
    margin-bottom: 24px;
  }
  .news-title {
    font-weight: 500;
    color: #2e3e56;
    font-size: 36px;
    margin-bottom: 32px;
    margin-top: 0;
  }
  .news-text {
    font-size: 18px;
    color: #2e3e56;
  }
  .go-to-back-button {
    display: flex;
    align-items: center;
    color: #0056d6;
    opacity: 0.8;
  }
  .go-to-back-button:hover {
    cursor: pointer;
    opacity: 1;
  }
  .arrow-back-icon {
    width: 24px;
    height: 22px;
    margin-right: 8px;
  }
  :global(.arrow-back-icon > svg) {
    fill: #0056d6;
    width: 20px;
    height: 20px;
  }
  .image-wrapper {
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: 25px;
    margin-bottom: 12px;
    display: flex;
  }
  img {
    width: 100%;
    border-radius: 24px;
  }
  @media (max-width: 767px) {
    img {
      max-width: 720px;
      padding: 0 24px;
      margin: auto;
    }
  }
  @media (min-width: 768px) {
    img {
      max-width: 720px;
      padding: 0 24px;
      margin: auto;
    }
  }
  @media (min-width: 992px) {
    img {
      max-width: 520px;
      padding: 0;
      margin: auto;
    }
  }
  @media (min-width: 1200px) {
    img {
      max-width: 720px;
      margin: auto;
      padding: 0;
    }
  }
  @media (min-width: 1400px) {
    img {
      max-width: 920px;
      margin: auto;
      padding: 0;
    }
  }
  .file {
    border-bottom: 1px solid transparent;
  }
  .file:hover {
    border-bottom: 1px dashed;
  }
  :global(.icon-clip > svg) {
    width: 24px;
    height: 24px;
  }
  :global(.icon-clip > svg > path) {
    fill: #0056d6;
  }
  .download-file {
    color: #0056d6;
    cursor: pointer;
    transition: all 0.3s;
    display: flex;
  }
  .download-file:hover {
    cursor: pointer;
    opacity: 0.8;
  }
  @media (max-width: 767px) {
    .news-container {
      padding: 35px 15px;
    }
    .news-title {
      text-align: left;
      font-size: 28px;
      align-self: flex-start;
      margin-bottom: 16px;
      margin-top: 0;
    }
    .news-date {
      align-self: center;
    }
  }
  @media (min-width: 768px) {
    .news-container {
      padding: 35px 16px;
    }
    .news-title {
      text-align: left;
      font-size: 28px;
      align-self: flex-start;
      margin-bottom: 24px;
      margin-top: 0;
    }
    .news-date {
      align-self: center;
    }
  }
  @media (min-width: 992px) {
    .news-container {
      padding: 35px 40px;
    }
    .news-title {
      font-size: 32px;
      margin-bottom: 24px;
      margin-top: 0;
    }
  }
  @media (min-width: 1200px) {
    .news-container {
      padding: 35px 44px;
    }
  }
  @media (min-width: 1400px) {
    .news-container {
      padding: 35px 60px;
    }
  }
</style>

<div class="news-container w-100 max-width">
  <div class="go-to-back-button" on:click={goToAllNews}>
    <div class="arrow-back-icon">
      <ArrowBack />
    </div>
    <div>Ко всем новостям</div>
  </div>
  {#if !!news.image}
    <div class="image-wrapper">
      <img src={news.image} />
    </div>
  {/if}
  <TextWrapper>
    <h1 class="news-title">{news.title}</h1>
    <div class="news-text">
      {@html news.content}
    </div>
    {#if news.files}
      <Download {news} />
    {/if}
    <div class="news-date">{news.date}</div>
  </TextWrapper>
  <Divider />
  <NewsPostFooter {prevNews} {nextNews} {goToPrevNews} {goToNextNews} />
</div>
