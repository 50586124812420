<script>
  import ServiceCard from "./ServiceCard.svelte";
  import { onMount } from "svelte";
  import WhiteButton from "components/Common/Buttons/WhiteButton.svelte";
  import ContentWrapper from "components/ContentWrapper.svelte";
  import ArrowUp from "static/ArrowIcon/ArrowUp.svelte";
  import ArrowDown from "static/ArrowIcon/ArrowDown.svelte";
  import serviceInfo from "../../../../services/Info.json";

  let isShowAllService = false;

  const showAllService = () => {
    isShowAllService = !isShowAllService;
  };

  let width, serviceCount, allService, mainService;

  const getValueServiceCount = () => {
    width = document.documentElement.clientWidth;
    let serviceCount;
    if (width < 373) serviceCount = 1;
    else if (width < 557) serviceCount = 2;
    else if (width < 765) serviceCount = 3;
    else if (width < 992) serviceCount = 4;
    else if (width >= 992) serviceCount = 5;
    allService = Object.values(serviceInfo).filter(
      service => !service.isInternal
    );
    mainService = allService.splice(0, serviceCount);
  };

  getValueServiceCount();

  onMount(() => {
    window.addEventListener("resize", getValueServiceCount);
  });
</script>

<style>
  .wrapper-cards {
    display: flex;
    justify-content: space-around;
    gap: 24px;
    flex-wrap: wrap;
  }
  .container-service {
    padding: 24px 0;
  }
  .button-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .button-icon-main {
    width: 24px;
    height: 24px;
    margin-right: 8px;
  }
  .service-wrapper {
    display: flex;
    width: 100%;
    opacity: 0;
    max-height: 0;
    overflow: hidden;
    transition: opacity 0.5s, max-height 0.5s;
    flex-wrap: wrap;
  }
  .expanded {
    flex-wrap: wrap;
    max-height: max-content;
    opacity: 1;
    display: flex;
    justify-content: center;
    gap: 16px;
  }
  .text-button {
    white-space: nowrap;
  }
  :global(.button-icon-main > svg > path) {
    fill: #0056d6;
  }
</style>

<div class="container-service max-width">
  <ContentWrapper>
    <div class="wrapper-cards">
      <div class="service-wrapper expanded">
        {#each mainService as service, i}
          <ServiceCard {service} isShowAllService="true" />
        {/each}
      </div>
      <div class="service-wrapper {isShowAllService && `expanded`}">
        {#each allService as service, i}
          <ServiceCard {service} {isShowAllService} />
        {/each}
      </div>
    </div>
  </ContentWrapper>
</div>
{#if allService.length}
  <WhiteButton onClick={showAllService}>
    <div class="button-container">
      <div class="button-icon-main">
        {#if !isShowAllService}
          <ArrowDown />
        {:else}
          <ArrowUp />
        {/if}
      </div>
      <div class="text-button">Все сервисы</div>
    </div>
  </WhiteButton>
{/if}
