<script>
  export let content;
  const rex = /(<([^>]+)>)/gi;
  let textContent = content.replace(rex, "");
</script>

<style>
  .news-text {
    color: #2e3e56;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-text-size-adjust: none;
    -moz-text-size-adjust: none;
    -ms-text-size-adjust: none;
  }
</style>

<p class="news-text">{textContent}</p>
