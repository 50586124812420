<script>
  import { onDestroy } from "svelte";
  import Success from "static/Success.svelte";
  import ErrorIcon from "static/ErrorIcon.svelte";
  import { getNotificationsContext } from "svelte-notifications";
  export let notification;
  const { removeNotification } = getNotificationsContext();
  const { id, deleteAfter, status } = notification;
  const removeNotificationHandler = () => removeNotification(id);
  const timeout = setTimeout(
    () => removeNotificationHandler(),
    deleteAfter || 4000
  );
  onDestroy(() => {
    if (timeout) clearTimeout(timeout);
  });
</script>

<style>
  :global(.position-bottom-right.default-position-style-bottom-right) {
    max-width: 500px;
    bottom: 60px;
    z-index: 1500;
    width: auto !important;
  }
  .notification {
    position: relative;
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    margin: 12px;
    background: #fff;
    color: #000;
    border-radius: 8px;
    box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
      0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  }
  .notification-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .notification-content {
    display: flex;
    align-items: center;
    padding: 18px;
    box-sizing: border-box;
    word-wrap: break-word;
  }
  .notification-content p {
    font-size: 14px;
    color: #a1a1a1;
    margin: 2px 0 0;
  }
  button {
    display: block;
    width: 40px;
    padding: 0 0 2px;
    margin: 0;
    border: none;
    border-left: 1px solid #eee;
    outline: none;
    background: none;
    cursor: pointer;
    font-size: 20px;
    color: #000;
    box-sizing: border-box;
  }
  button:hover {
    background: rgba(0, 0, 0, 0.01);
  }
  .success-icon {
    margin-right: 12px;
    width: 40px;
    height: 40px;
  }
  :global(.success-icon > svg) {
    width: 40px;
    height: 40px;
  }
</style>

<div class="notification">
  <div class="notification-content">
    <div class="success-icon">
      {#if status === 'success'}
        <Success />
      {/if}
      {#if status === 'error'}
        <ErrorIcon />
      {/if}
    </div>
    <div class="notification-info">
      <slot>{notification.text}</slot>
      {#if notification.description}
        <p>{notification.description}</p>
      {/if}
    </div>
  </div>
  <button on:click={removeNotificationHandler}>&times;</button>
</div>
