<script>
  export let className;
</script>

<svg
  class={className}
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
  xmlns="http://www.w3.org/2000/svg">
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M5.79285 9.70706L7.20706 8.29285L12 13.0857L16.7928 8.29285L18.2071
    9.70706L12 15.9142L5.79285 9.70706Z"
    />
</svg>
