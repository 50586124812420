<script>
  import { onMount } from "svelte";
  import registerField from "components/Integration/registeredFields";
  import FormWrapper from "components/Integration/FormWrapper.svelte";
  import Actions from "components/Integration/Actions.svelte";
  import { getNotificationsContext } from "svelte-notifications";

  export let form;

  const format = (value, pattern) => {
    let i = 0,
      v = value.toString();
    return pattern.replace(/#/g, _ => v[i++]);
  };
  const { addNotification } = getNotificationsContext();

  onMount(() => {
    Array.from(document.forms[0]).forEach(input => {
      if (!!input.name) {
        registerField(input, form);
      }
    });

    document.getElementById("form").addEventListener("submit", async event => {
      event.preventDefault();
      const element = document.querySelector(".selectContainer")
        .lastElementChild;
      element.addEventListener("click", event => {
        event.stopPropagation();
      });

      const clearForm = () => {
        form.reset();
        element.click();
      };

      try {
        let result = await form.submit();
        if (!result || result.status !== 200) {
          addNotification({
            text: "Ошибка при отправке заявки",
            description:
              "Исправьте ошибки в заполнение полей или обратитесь в службу технической поддержки",
            position: "bottom-right",
            status: "error"
          });
        } else {
          clearForm();
          addNotification({
            text: "Ваша заявка была отправлена",
            description: "Наши менеджеры скоро с Вами свяжутся",
            position: "bottom-right",
            status: "success"
          });
        }
      } catch (e) {
        console.log("error", e);
        addNotification({
          text: "Ошибка при отправке заявки",
          description: "Обратитесь в службу технической поддержки",
          position: "bottom-right",
          status: "error"
        });
      }
    });
  });
</script>

<style>
  #form {
    margin: 50px 0;
  }
  .advantage-header {
    text-align: center;
    font-weight: 300;
    font-size: 28px;
    margin-bottom: 50px;
  }
</style>

<form id="form">
  <div class="advantage-header" id="integration">Заявка на интеграцию</div>
  <FormWrapper {form} />
  <Actions />
</form>
