<script>
  import { onDestroy, onMount } from "svelte";
  import { activeService, selectedServices } from "../../store";
  import MenuWrapper from "../Content/MenuWrapper.svelte";
  import TextWrapper from "components/TextWrapper.svelte";
  import Divider from "components/Common/Divider.svelte";
  import HeaderContent from "components/Content/HeaderContent.svelte";

  import TabContainer from "components/Common/Tabs/TabContainer.svelte";
  import { TABS_INFO_CONTENT } from "constants/tabs";
  import serviceInfo from "../../../services/Info.json";

  export let type;
  window.scrollTo(0, 0);
  let isSelectService = false;
  onMount(() => {
    let selectService = Object.values(serviceInfo)
      .filter(service => !service.isInternal)
      .find(service => service.name === type);
    let serviceTitle = selectService && selectService.title;
    if (
      serviceTitle &&
      !$selectedServices.map(service => service.value).includes(serviceTitle)
    ) {
      isSelectService = true;
      selectedServices.setSelectService([...$selectedServices, serviceTitle]);
    }
  });
  onDestroy(() => {
    let selectService = Object.values(serviceInfo).find(
      service => service.name === type
    );
    let serviceTitle = selectService && selectService.title;
    if (
      serviceTitle &&
      isSelectService &&
      $selectedServices.map(service => service.value).includes(serviceTitle)
    )
      selectedServices.setSelectService(
        $selectedServices.filter(service => service.value !== serviceTitle)
      );
  });

  let isLeftMenuOpen = false;
  let isRightMenuOpen = false;
  const toggleLeftMenu = () => {
    isRightMenuOpen = false;
    isLeftMenuOpen = !isLeftMenuOpen;
  };

  const toggleRightMenu = () => {
    isLeftMenuOpen = false;
    isRightMenuOpen = !isRightMenuOpen;
  };

  const setFalse = () => {
    isLeftMenuOpen = false;
    isRightMenuOpen = false;
  };

  if (!Object.keys($activeService).length) {
    activeService.setActiveService(serviceInfo[type]);
  }
  if ($activeService.name !== type) {
    activeService.setActiveService(serviceInfo[type]);
  }

  const {
    title,
    icon,
    name,
    description,
    cases,
    structure,
    isHideCases
  } = $activeService;

  const tabInfoArray = TABS_INFO_CONTENT.filter(
    el => !(el.name === "cases" && isHideCases)
  ).map((el, index) => {
    return {
      ...el,
      index,
      menuContent: $activeService[el.name]
    };
  });
</script>

<style>
  .content-container {
    padding-top: 42px;
  }
  .about-info {
    margin-top: 24px;
    font-size: 15px;
    font-weight: 500;
  }
  .about-info p {
    margin: 8px 0;
  }
</style>

<div class="content-container">
  <div>
    <HeaderContent {title} {icon} {name} {setFalse} {tabInfoArray} />
    <Divider />
    <TabContainer>
      {#each tabInfoArray as tab, i}
        {#if i === 0}
          <div class="about-info">
            <TextWrapper>
              {@html description.content}
            </TextWrapper>
          </div>
        {:else}
          <MenuWrapper
            menuContent={tab.menuContent}
            tabInfo={tab}
            {isLeftMenuOpen}
            {isRightMenuOpen}
            {setFalse}
            {toggleLeftMenu}
            {toggleRightMenu} />
        {/if}
      {/each}
    </TabContainer>
  </div>
</div>
