import qs from "qs";
import { getFileName } from "./index";
/**
 * Получение хеша по location
 * @param location - данные url
 */
export const getHash = location => {
  return location.hash.replace("#", "");
};

/**
 * Получение объекта параметров в url строке по location
 * @param location - данные url
 */
export const getParams = location => {
  return qs.parse(location.search, { ignoreQueryPrefix: true });
};

/**
 * Получение необходимого параметра в url строке по location
 * @param location - данные url
 * @param param - параметр, который необходимо вернуть
 */
export const getValueLocationParam = location => {
  const params = qs.parse(location.search, { ignoreQueryPrefix: true });
  if (params) {
    return params;
  } else {
    return 0;
  }
};

/**
 * Установка URL сервиса по параметрам
 * @param name - Название сервиса
 * @param rest - Параметры
 */
export const setServiceUrl = ({ name, ...rest }) => {
  let params = qs.stringify(rest, { addQueryPrefix: true });
  if (name) return `/service/${name}${params}`;
};

/**
 * Установка URL сервиса по параметрам
 * @param rest - Параметры
 */
export const setDocUrl = ({ ...rest }) => {
  let params = qs.stringify(rest, { addQueryPrefix: true });
  return `/documentation${params}`;
};

/**
 * Замена хеша для сваггера
 * @param location - данные url
 */
export const replaceSwaggerHash = location =>
  decodeURIComponent(location.hash)
    .replace(/_/g, " ")
    .replace("#", "")
    .split("—");

/**
 * Создание ссылки на скачивание файла
 * @param file - путь к файлу
 */
export const createLink = file => {
  const fileName = getFileName(file);
  const a = document.createElement("a");
  const url = file;
  a.href = url;
  a.download = fileName;
  document.body.appendChild(a);
  a.click();
  setTimeout(() => {
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  }, 0);
};
