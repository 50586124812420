/**
 * Получение названия файла по пути
 * @param path - путь к файлу
 */
export const getFileName = path => {
  return path
    .split("\\")
    .pop()
    .split("/")
    .pop();
};
