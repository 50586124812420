<script>
  import RightMenu from "./RightMenu.svelte";
  import ContentDoc from "./ContentDoc.svelte";
  import LeftMenu from "./LeftMenu.svelte";
  import ArrowBack from "static/ArrowToBack.svelte";
  import ServiceButton from "components/Common/Buttons/ServiceButton.svelte";
  import HeaderNavigation from "./HeaderNavigations.svelte";
  import ContentWrapper from "components/ContentWrapper.svelte";
  import FilePlaceholder from "components/Common/FilePlaceholder.svelte";
  import PlaceholderImage from "static/PlaceholderImage";
  import { changeMenuDuringScroll } from "utils/scrollUtils";
  import TextWrapper from "components/TextWrapper.svelte";

  export let tabInfo;
  export let menuContent;
  export let selectedCase;
  export let activeDocInTab;
  export let changeCase;
  export let nextTitle;
  export let prevTitle;
  export let isLeftMenuOpen;
  export let isRightMenuOpen;
  export let setFalse;
  export let toggleLeftMenu;
  export let toggleRightMenu;
  const placeholderText = `Раздел "${tabInfo && tabInfo.title}" в разработке`;
  /**
   * Выбор документации по клику
   * @param parentId - номер категории
   * @param id - номер документации
   */
  const changeCaseObject = ({ parentId, id }) => {
    changeMenuDuringScroll()({ unset: true });
    window.scrollTo(0, 0);
    setFalse();
    const activeElementsLevel1 = document.querySelector(
      ".tab_panel.active-tab .level1.menu-item-active"
    );
    const newActiveElementLevel1 = document.querySelector(
      `.tab_panel.active-tab .level1`
    );
    if (activeElementsLevel1 !== newActiveElementLevel1) {
      activeElementsLevel1 &&
        activeElementsLevel1.classList.remove("menu-item-active");
      newActiveElementLevel1 &&
        newActiveElementLevel1.classList.add("menu-item-active");
    }
    if (changeCase) {
      changeCase({ parentId, id });
    } else {
      throw new Error(
        "Необходимо добавить метод changeCase для выбора документации"
      );
    }
  };

  const nextDoc = () => {
    if (Array.isArray(menuContent)) {
      if (menuContent[activeDocInTab.dataIndex + 1]) {
        changeCaseObject({
          id: activeDocInTab.dataIndex + 1
        });
      }
    } else {
      if (
        menuContent.categories[activeDocInTab.categoryIndex].data[
          activeDocInTab.dataIndex + 1
        ]
      ) {
        changeCaseObject({
          id: activeDocInTab.dataIndex + 1,
          parentId: activeDocInTab.categoryIndex
        });
      } else if (menuContent.categories[activeDocInTab.categoryIndex + 1]) {
        changeCaseObject({
          id: 0,
          parentId: activeDocInTab.categoryIndex + 1
        });
      }
    }
  };
  const prevDoc = () => {
    if (Array.isArray(menuContent)) {
      if (menuContent[activeDocInTab.dataIndex - 1]) {
        changeCaseObject({
          id: activeDocInTab.dataIndex - 1
        });
      }
    } else {
      if (
        menuContent.categories[activeDocInTab.categoryIndex].data[
          activeDocInTab.dataIndex - 1
        ]
      ) {
        changeCaseObject({
          id: activeDocInTab.dataIndex - 1,
          parentId: activeDocInTab.categoryIndex
        });
      } else if (menuContent.categories[activeDocInTab.categoryIndex - 1]) {
        changeCaseObject({
          id:
            menuContent.categories[activeDocInTab.categoryIndex - 1].data
              .length - 1,
          parentId: activeDocInTab.categoryIndex - 1
        });
      }
    }
  };
</script>

<style>
  .menu-data {
    width: 100%;
    height: 100%;
  }
  .placeholder-wrapper {
    width: 100%;
  }
  .doc-wrapper {
    display: flex;
    flex-flow: row nowrap;
    flex-grow: 1;
    width: 100%;
    justify-content: flex-start;
    align-items: flex-start;
    flex-flow: row nowrap;
    margin: 0 auto;
  }
  :global(.doc-wrapper > :first-child) {
    margin-left: 0;
  }
  :global(.doc-wrapper > :last-child) {
    margin-right: 0;
  }
  :global(.doc-wrapper > *) {
    margin-top: 24px;
  }
  .nav-button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 36px;
  }
  :global(.prev-doc-button) {
    align-self: flex-start;
  }
  :global(.next-doc-button) {
    display: flex;
    align-items: center;
    align-self: flex-end;
    margin-left: auto;
  }
  .next-button-icon {
    width: 16px;
    height: 16px;
    transform: rotate(180deg);
    margin-left: 12px;
    display: flex;
    align-items: center;
  }
  :global(.next-button-icon > svg) {
    width: 16px;
    height: 16px;
  }
  .prev-button-icon {
    width: 16px;
    height: 16px;
    margin-right: 12px;
    display: flex;
    align-items: center;
  }
  :global(.prev-button-icon > svg) {
    width: 16px;
    height: 16px;
  }

  @media (max-width: 1200px) {
    .doc-wrapper.open-left {
      flex-flow: column;
    }

    .doc-wrapper.open-right {
      flex-flow: column-reverse;
    }
    :global(.doc-wrapper > *) {
      margin-left: 0;
      margin-right: 0;
    }
  }
  @media (max-width: 767px) {
    .nav-button {
      flex-direction: column;
      width: 100%;
    }
    :global(.prev-doc-button) {
      align-self: unset;
    }
    :global(.next-doc-button) {
      align-self: unset;
      margin-left: 0;
    }
  }
</style>

<div id="menu-wrapper" class="tabIndex{tabInfo.index}">
  {#if menuContent}
    <HeaderNavigation
      {menuContent}
      {tabInfo}
      {activeDocInTab}
      {toggleLeftMenu}
      {toggleRightMenu}
      {isLeftMenuOpen}
      {isRightMenuOpen} />
    <div
      id="doc-wrapper"
      class="doc-wrapper max-width {isLeftMenuOpen && 'open-left'}
      {isRightMenuOpen && 'open-right'}">
      <LeftMenu
        {menuContent}
        {tabInfo}
        {activeDocInTab}
        {changeCaseObject}
        {isLeftMenuOpen} />
      <ContentDoc
        {selectedCase}
        {isLeftMenuOpen}
        {isRightMenuOpen}
        {tabInfo} />
      <RightMenu {selectedCase} {isRightMenuOpen} {setFalse} {tabInfo} />
    </div>
    <div class="max-width">
      <TextWrapper>
        <div class="nav-button">
          {#if prevTitle}
            <ServiceButton onClick={prevDoc} className={'prev-doc-button'}>
              <div class="button-icon prev-button-icon">
                <ArrowBack />
              </div>
              <span>{prevTitle}</span>
            </ServiceButton>
          {/if}
          {#if nextTitle}
            <ServiceButton onClick={nextDoc} className={'next-doc-button'}>
              <span>{nextTitle}</span>
              <div class="button-icon next-button-icon">
                <ArrowBack />
              </div>
            </ServiceButton>
          {/if}
        </div>
      </TextWrapper>
    </div>
  {:else}
    <div class="placeholder-wrapper">
      <FilePlaceholder
        placeholderTitle={placeholderText}
        placeholderText="Уважаемый посетитель, приносим свои извинения, но
        данная страница сейчас находится в разработке. Публикация ожидается в
        ближайшее время. Спасибо за понимание."
        {PlaceholderImage} />
    </div>
  {/if}
</div>
