<script>
  import { onMount } from "svelte";
  import LeftHeader from "./WhiteHeader/LeftHeader.svelte";
  import RightHeader from "./WhiteHeader/RightHeader.svelte";
  import ContentWrapper from "components/ContentWrapper.svelte";

  export let getProps;

  const checkMobile = () => {
    const width = document.documentElement.clientWidth;
    return width <= 988;
  };

  let isMobile = checkMobile();
  onMount(() => {
    window.addEventListener("resize", () => {
      isMobile = checkMobile();
    });
  });

</script>

<style>
  header {
    position: relative;
    margin-bottom: 50px;
    background: #0056d6 url("/BackgroundHeader.svg") center repeat;
  }
  header.top-header {
    box-shadow: none;
    margin-bottom: 0;
  }
  .mobile {
    position: sticky;
    top: 0;
    z-index: 1000;
  }
  .white-header-top {
    background-color: white;
  }
  .container-white {
    height: 98px;
    background-color: white;
    color: black;
  }
  .row {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .header-width {
    max-width: 1400px;
    margin: auto;
  }
  @media (max-width: 767px) {
    .container-white {
      height: 50px;
    }
  }
  @media (min-width: 768px) {
    .container-white {
      height: 70px;
    }
  }
  @media (min-width: 992px) {
    .container-white {
      height: 70px;
    }
  }
</style>

<header class="top-header {isMobile && 'mobile'}">
  <div class="white-header-top h-100 w-100">
    <div class="container-white w-100 header-width">
      <ContentWrapper>
        <div class="row">
          <LeftHeader {getProps} />
          <RightHeader {getProps} />
        </div>
      </ContentWrapper>
    </div>
  </div>
</header>
