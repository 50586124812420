<script>
  import { navigate } from "svelte-routing";
  import FilePlaceholder from "components/Common/FilePlaceholder.svelte";
  import PlaceholderImage from "static/PlaceholderImage";
  import Button from "components/Common/Buttons/Button.svelte";

  const handleClick = () => {
    navigate("/");
  };
</script>

<style>
  .container {
    display: flex;
    width: 100%;
    height: 100%;
    flex: 1;
  }
  .wrapper {
    width: 100%;
    height: 100%;
    max-width: 1200px;
    margin: auto;
    align-items: stretch;
  }
</style>

<div class="container">
  <div class="wrapper">
    <FilePlaceholder
      placeholderTitle="Личный кабинет находится в разработке"
      placeholderText="Уважаемый посетитель, приносим свои извинения, но Личный
      кабинет сейчас находится в разработке. Ожидается в ближайшее время.
      Спасибо за понимание."
      {PlaceholderImage} />
  </div>
</div>
