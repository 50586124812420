<script>
  import ContentWrapper from "components/ContentWrapper.svelte";
  import AboutIcon from "static/AboutIcon.svelte";
  window.scrollTo(0, 0);
</script>

<style>
  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 24px 0;
  }
  .top-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 96px;
    flex-wrap: wrap;
  }
  .top-info > div {
    padding: 0 60px;
  }
  .left-top-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 30%;
  }
  .about-icon {
    width: 186px;
    height: 149px;
    margin-bottom: 50px;
  }
  .left-info {
    text-align: center;
    font-size: 16px;
  }
  .right-top-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 70%;
  }
  .right-title {
    font-size: 40px;
    font-weight: 500;
    width: 100%;
    color: #000;
  }
  .right-info {
    font-size: 18px;
  }
  .bottom-container {
    padding: 0 60px;
  }
  .blue-container {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 22px;
    background: #0056d6 url("/AboutBackground.svg") center no-repeat;
  }
  .bottom-info {
    color: #fff;
    font-size: 24px;
    font-weight: 300;
    padding: 72px 104px;
    text-align: center;
  }
  @media (max-width: 767px) {
    .container {
      margin: 24px 14px 44px;
      text-align: center;
    }
    .top-info {
      flex-direction: column-reverse;
      margin-bottom: 44px;
    }
    .top-info > div {
      padding: 0 14px;
      width: 100%;
    }
    .top-info > div:not(:first-child) {
      margin-bottom: 44px;
    }
    .bottom-container {
      padding: 0 14px;
    }
    .right-title {
      font-size: 40px;
      font-weight: 500;
      width: 100%;
    }
    .right-info {
      font-size: 14px;
    }
    .about-icon {
      width: 140px;
      height: 110px;
      margin-bottom: 12px;
    }
    :global(.about-icon > svg) {
      width: 140px;
      height: 110px;
    }
    .left-info {
      font-size: 14px;
      width: 50%;
    }
    .bottom-info {
      font-size: 14px;
      font-weight: 300;
      padding: 40px 24px;
    }
  }
  @media (min-width: 768px) {
    .container {
      margin: 24px 14px;
      text-align: left;
    }
    .top-info {
      flex-direction: row;
      margin-bottom: 36px;
    }
    .top-info > div {
      padding: 0 14px;
    }
    .bottom-container {
      padding: 0 14px;
    }
    .right-title {
      font-size: 40px;
      font-weight: 500;
    }
    .right-info {
      font-size: 16px;
    }
    .about-icon {
      width: 140px;
      height: 110px;
      margin: 12px 0;
    }
    :global(.about-icon > svg) {
      width: 140px;
      height: 110px;
    }
    .left-info {
      font-size: 14px;
    }
    .bottom-info {
      font-size: 14px;
      font-weight: 300;
      padding: 40px 24px;
    }
  }
  @media (min-width: 992px) {
    .container {
      text-align: left;
      margin: 24px 0 36px;
    }
    .top-info > div {
      padding: 0 24px;
    }
    .about-icon {
      width: 120px;
      height: 90px;
      margin: 24px 0;
    }
    :global(.about-icon > svg) {
      width: 120px;
      height: 90px;
    }
    .left-info {
      font-size: 16px;
    }
    .right-title {
      font-size: 40px;
    }
    .right-info {
      font-size: 16px;
    }
    .bottom-container {
      padding: 0 36px;
    }
    .bottom-info {
      font-size: 16px;
      padding: 60px 36px;
    }
  }
  @media (min-width: 1200px) {
    .container {
      margin: 24px 0 50px;
    }
    .top-info > div {
      padding: 0 24px;
    }
    .about-icon {
      width: 130px;
      height: 100px;
      margin: 24px 0;
    }
    :global(.about-icon > svg) {
      width: 130px;
      height: 100px;
    }
    .left-info {
      font-size: 16px;
    }
    .right-title {
      font-size: 40px;
    }
    .right-info {
      font-size: 18px;
    }
    .bottom-container {
      padding: 0 36px;
    }
    .top-info {
      margin-bottom: 50px;
    }
    .bottom-info {
      font-size: 24px;
      padding: 72px 104px;
    }
  }
  @media (min-width: 1400px) {
    .container {
      margin: 24px 0 50px;
    }
    .top-info {
      margin-bottom: 70px;
    }
    .top-info > div {
      padding: 0 60px;
    }
    .left-top-info {
      width: 30%;
    }
    .about-icon {
      width: 150px;
      height: 115px;
      margin: 24px 0 36px;
    }
    :global(.about-icon > svg) {
      width: 150px;
      height: 115px;
    }
    .left-info {
      text-align: center;
      font-size: 16px;
    }
    .right-top-info {
      width: 70%;
    }
    .right-title {
      font-size: 40px;
      font-weight: 500;
      width: 100%;
    }
    .right-info {
      font-size: 18px;
    }
    .bottom-container {
      padding: 0 60px;
    }
    .bottom-info {
      font-size: 24px;
      padding: 72px 104px;
    }
  }
</style>

<div class="container">
  <div class="max-width">
    <ContentWrapper>
      <div class="top-info ">
        <div class="left-top-info">
          <div class="about-icon">
            <AboutIcon />
          </div>
          <div class="left-info">
            Более подробную информацию о&nbsp;нас и&nbsp;наших продуктах
            вы&nbsp;найдете на&nbsp;нашем сайте:
            <a href="https://astral.ru">astral.ru</a>
          </div>
        </div>
        <div class="right-top-info">
          <div class="right-title">О&nbsp;нас</div>
          <div class="right-info">
            Группа компаний «Астрал» является стратегическим партнером Фирмы 1С и одним из крупнейших российских разработчиков сервисов для бизнеса. В ГК “Астрал” входит АО “Калуга Астрал” – аккредитованный удостоверяющий центр, оператор электронного документооборота и фискальных данных, а также оператор системы электронных перевозочных документов
          </div>
          <br/>
          <div class="right-info">
            Астрал.Платформа – один из ключевых продуктов компании, объединяющий API-версии сервисов ГК “Астрал” для интеграции возможностей собственных IT-продуктов в сторонние информационные системы
          </div>
          <br/>
          <div class="right-info">
            API является открытым и бесплатным, как и поддержка внедрения. Разработчикам предоставляется доступ к демо-стенду и выпуск тестовых электронных подписей
          </div>
          <br/>
          <div class="right-info">
            На данный момент реализовано более 100 интеграционных проектов, в том числе решения для электронной отчетности, юридически значимого документооборота, обмена электронными перевозочными документами, выпуску и применению электронных подписей
          </div>
        </div>
      </div>
    </ContentWrapper>
  </div>
  <div class="max-width">
    <ContentWrapper>
      <div class="bottom-container blue-container">
        <div class="bottom-info">
          Приглашаем к партнерству вендоров цифровых продуктов, финтех-сервисы, разработчиков учетных систем, логистические платформы и компании, заинтересованные в автоматизации бизнес-процессов и развитии своих информационных систем
        </div>
      </div>
    </ContentWrapper>
  </div>
</div>
