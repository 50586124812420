/**
 *Включение кнопки наверх
 * @param goTopBtn - Элемент кнопки
 */
export const trackScroll = goTopBtn => () => {
  const scrolled = window.pageYOffset;
  const coords = document.documentElement.clientHeight;
  if (scrolled > coords) {
    goTopBtn.classList.add("back_to_top_show");
  }
  if (scrolled < coords) {
    goTopBtn.classList.remove("back_to_top_show");
  }
};

/**
 * Возвращение позиции страницы наверх при клике
 */
export const backToTop = () => {
  if (window.pageYOffset > 0) {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }
};

/**
 * Проверка видимости элемента и сколл, чтобы его было видно
 * @param target - Элемент
 */
export const visibilityElement = target => {
  // Все позиции элемента
  if (target) {
    // Все позиции элемента
    const targetPosition = {
        top: window.pageYOffset + target.getBoundingClientRect().top,
        bottom: window.pageYOffset + target.getBoundingClientRect().bottom
      },
      // Получаем позиции окна
      windowPosition = {
        top: window.pageYOffset,
        bottom: window.pageYOffset + document.documentElement.clientHeight
      };

    if (!(targetPosition.bottom - 400 > windowPosition.top)) {
      document.getElementById(`menu-scrollable-tree${index}`).scrollTop -= 50;
    } else if (!(targetPosition.top + 400 < windowPosition.bottom)) {
      document.getElementById(`menu-scrollable-tree${index}`).scrollTop += 50;
    }
  }
};

/**
 * Изменение активного заголовка меню во время скрола
 */
export const changeMenuDuringScroll = () => ({ unset = false }) => {
  const width = document.documentElement.clientWidth;
  const positionTop = width >= 992 ? 150 : 250;
  let section = document.querySelectorAll(`.active-tab h2, .active-tab h3`),
    sections = {},
    i = 0;
  Array.prototype.forEach.call(section, function(e) {
    sections[e.id] = e;
  });
  for (i in sections) {
    const toTopValue = sections[i].getBoundingClientRect().top;
    if (toTopValue <= positionTop) {
      const activeElementsLevel1 = document.querySelector(
        ".active-tab .level1.menu-item-active"
      );
      const activeElementsLevel2 = document.querySelector(
        ".active-tab .level2.menu-item-active"
      );
      const newActiveElementLevel1 = document.querySelector(
        `.active-tab li[data-href="#${i}"].level1`
      );
      const newActiveElementLevel2 = document.querySelector(
        `.active-tab li[data-href="#${i}"].level2`
      );
      const isLevel1 = !!newActiveElementLevel1;
      const isLevel2 = !!newActiveElementLevel2;
      if (unset) {
        if (isLevel1) {
          activeElementsLevel1 &&
            activeElementsLevel1.classList.remove("menu-item-active");
          document
            .querySelector(".active-tab li.level1")
            .classList.add("menu-item-active");
        }

        if (isLevel2) {
          let level1 = activeElementsLevel1.dataset.id;
          activeElementsLevel2 &&
            activeElementsLevel2.classList.remove("menu-item-active");
          document
            .querySelector(".active-tab li.level2")
            .classList.add("menu-item-active")
            .classList.add("menu-item-active");
          visibilityElement(newActiveElementLevel2.parentElement);
        }
      } else {
        if (isLevel1) {
          activeElementsLevel1 &&
            activeElementsLevel1.classList.remove("menu-item-active");
          newActiveElementLevel1.classList.add("menu-item-active");
        }
        if (isLevel2) {
          let level1 = activeElementsLevel1.dataset.id;
          activeElementsLevel2 &&
            activeElementsLevel2.classList.remove("menu-item-active");
          newActiveElementLevel2.classList.add("menu-item-active");
          visibilityElement(newActiveElementLevel2.parentElement);
        }
      }
    }
  }
};

/**
 * Блокировка горизонтального скролла
 */
export const blockHorizontalScroll = () => {
  if (window.scrollX !== 0) window.scrollX = 0;
};
