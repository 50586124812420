<script>
  import TextWrapper from "components/TextWrapper.svelte";
  import Divider from "components/Common/Divider.svelte";
  import Notifications from "svelte-notifications";
  import NotificationItem from "components/Common/NotificationItem";
  import Form from "components/Integration/Form.svelte";
  let n;

  export let isMain;
  export let form;
</script>

{#if !isMain}
  <Divider />
{/if}
<TextWrapper>
  <Notifications item={NotificationItem}>
    <Form {form} />
  </Notifications>
</TextWrapper>
