<script>
  import { navigate } from "svelte-routing";
  import { activeNews } from "../../store";
  import newsInfo from "../../../news/newsInfo.json";
  import NewsText from "./NewsText.svelte";
  import Masonry from "svelte-bricks";

  const openNews = news => {
    activeNews.setActiveNews(news);
    navigate(`news/${news.id}`);
  };
</script>

<style>
  .news-header {
    font-size: 40px;
    font-weight: 500;
    color: #000;
    margin-bottom: 12px;
  }
  .news-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 24px 0 0;
  }
  .news-date {
    font-size: 16px;
    opacity: 0.5;
    margin-bottom: 8px;
  }
  .news-title {
    font-weight: 500;
    font-size: 20px;
    color: #2e3e56;
    margin: 0;
  }
  :global(.icon-clip > svg) {
    width: 24px;
    height: 24px;
  }
  :global(.icon-clip > svg > path) {
    fill: #0056d6;
  }
  .news-card {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    overflow: hidden;
  }
  .news .news-card {
    border-bottom: 1px solid #dfdfdf;
  }
  .content-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .content-wrapper:hover {
    cursor: pointer;
  }
  .image-wrapper {
    width: 100%;
    height: 100%;
    position: relative;
    padding-bottom: 56%;
    overflow: hidden;
    border-radius: 25px;
    margin: 12px 0;
    display: flex;
  }
  img {
    width: 100%;
    height: 100%;
    position: absolute;
  }
  @media (max-width: 768px) {
    .news:not(:last-child) .news-card {
      border-bottom: 1px solid #dfdfdf;
    }
  }
  @media (min-width: 768px) {
    .news .news-card {
      border-bottom: 1px solid #dfdfdf;
    }
  }
  @media (min-width: 992px) {
    .news .news-card {
      border-bottom: 1px solid #dfdfdf;
    }
  }
  @media (min-width: 1200px) {
    .news .news-card {
      border-bottom: 1px solid #dfdfdf;
    }
  }
</style>

<div class="news-container">
  <div class="news-header">Новости</div>
</div>
<Masonry items={newsInfo} minColWidth={350} maxColWidth={600} gap={24} let:item>
  <div class="news grid-item">
    <div class="news-card">
      <div class="content-wrapper" on:click={() => openNews(item)}>
        {#if !!item.image}
          <div class="image-wrapper">
            <img src={item.image} />
          </div>
        {/if}
        <h2 class="news-title">{item.title}</h2>
        <NewsText content={item.content} />
        <div class="news-date">{item.date}</div>
      </div>
    </div>
  </div>
</Masonry>
