<script>
  export let tabInfo;
  export let changeCaseObject;
  export let activeDocInTab;
  export let title;
  export let i;
  export let j;
  export let isArray;
</script>

<style>
  .menu-list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
  }
  .menu-item-wrapper {
    position: relative;
    width: 100%;
  }
  .header-wrapper {
    display: flex;
    align-items: center;
    border-left: 3px solid transparent;
    width: 100%;
  }
  .header-wrapper:hover {
    background-color: rgba(0, 86, 214, 0.06);
  }
  .menu-item-active {
    border-color: #0056d6;
  }
  .menu-item-active div {
    color: #0056d6;
  }
  .header-menu {
    font-weight: 700;
    font-size: 14px;
    color: rgba(46, 62, 86, 0.5);
    padding: 12px 12px;
    width: 100%;
    text-decoration: none;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
</style>

<div class="menu-list">
  <div class="menu-item-wrapper">
    {#if !isArray}
      <li
        class="header-wrapper {j === activeDocInTab.dataIndex && i === activeDocInTab.categoryIndex && 'menu-item-active'}">
        <div
          class="header-menu"
          on:click={() => {
            changeCaseObject({ parentId: i, id: j });
          }}>
          {title}
        </div>
      </li>
    {:else}
      <li
        class="header-wrapper {i === activeDocInTab.dataIndex && 'menu-item-active'}">
        <div
          class="header-menu"
          on:click={() => {
            changeCaseObject({ id: i });
          }}>
          {title}
        </div>
      </li>
    {/if}
  </div>
</div>
