<script>
  import ArrowDown from "static/ArrowIcon/ArrowDown.svelte";
  import Divider from "components/Common/Divider.svelte";
  export let i;
  export let activeDocInTab;
  export let showMenu;
  export let name;
  export let openedMenuIndexes;
</script>

<style>
  .menu-item-wrapper {
    position: relative;
    width: 100%;
  }
  .header-wrapper {
    display: flex;
    align-items: center;
    width: 100%;
  }
  .header-wrapper:hover {
    background-color: rgba(0, 86, 214, 0.06);
  }
  .menu-item-active {
    border-color: #0056d6;
  }
  .menu-item-active div {
    color: #0056d6;
  }
  .header-menu {
    font-weight: 700;
    font-size: 14px;
    color: rgba(46, 62, 86, 0.5);
    padding: 12px 12px;
    width: 100%;
    text-decoration: none;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  :global(svg.menu-icon-open) {
  }
  :global(svg.menu-icon-close) {
    transform: rotate(-90deg);
  }
  :global(svg.menu-icon) {
    transition: all 0.3s;
    fill: rgba(46, 62, 86, 0.5);
    margin-left: 8px;
  }
  :global(svg.menu-icon-active) {
    fill: #0056d6;
  }
</style>

<div class="menu-item-wrapper">
  <li
    class="header-wrapper {i === activeDocInTab.categoryIndex && 'menu-item-active'}">
    <div
      class="header-menu"
      on:click={() => {
        showMenu(i);
      }}>
      {name}
      <div>
        <ArrowDown
          className="menu-icon {openedMenuIndexes.includes(i) ? 'menu-icon-open' : 'menu-icon-close'}
          {i === activeDocInTab.categoryIndex && 'menu-icon-active'}" />
      </div>
    </div>
  </li>
</div>
<!--{#if openedMenuIndexes.includes(i)}-->
<!--  <Divider />-->
<!--{/if}-->
