<script>
  import TopHeader from "./CommonHeader/TopHeader.svelte";
  import BottomHeader from "./CommonHeader/BottomHeader.svelte";

  export let getProps;
  export let newsInfo;

</script>

<style>
  header {
    position: relative;
    margin-bottom: 50px;
    background: #0056d6 url("/BackgroundHeader.svg") center repeat;
  }
  header.top-header {
    box-shadow: none;
    margin-bottom: 0;
  }
</style>

<header class="top-header">
  <TopHeader {getProps} />
  <BottomHeader {newsInfo} />
</header>
